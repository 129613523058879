export enum SubscriptionStatus {
    EXPIRED= "Expired",
    REJECTED= "Rejection",
    PENDING= "To validate",
    APPROVED= "In Progress"
}

export enum Language {
    FR = "fr",
    EN = "en"
}