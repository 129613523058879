import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import Utilities from "../helpers/utilities";
import Notification from "../helpers/notification";
//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
import User from "../models/users";
import UserService from "../services/user-service";
import { useTranslation } from "react-i18next";
import { constants } from "../models/constants";
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'


/**
* Form Fields
*/
type Field = {
    value: any,
    error: any,
    isValid?: boolean
}

/**
* Form object
*/
type Form = {
    email: Field,
    password: Field
}

type Props = {
    isModalOpen: boolean,
    clearModal: Function,
    updateManageUserGrid: Function,
    applicationId: number
};
const AddUserDetailsItemModal: FunctionComponent<Props> = ({isModalOpen, clearModal, updateManageUserGrid, applicationId}) => {

    const [checkEmail, setCheckEmail] = useState<boolean>(false);
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [form, setForm] = useState<Form>({
        email: {value: '', error:'', isValid: true},
        password: {value: '', error:'', isValid: true}
    });
    const { t } = useTranslation();

    // Handles Login input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.target.name;
        const fieldValue: string = e.target.value;
        const newField = {[fieldName]: { value: fieldValue }};

        //Using the spread operator we pass all newField props to loginForm props
        setForm({...form, ...newField})
    };

    /**
     * Login form validation
     */
    const validateForm= () => {

        let newForm: Form = form;
        if (Utilities.validEmail(form.email.value)) {
            
            const newField: Field = {value: form.email.value, error: '', isValid: true};
            newForm = {...newForm, ...{email: newField}};
        } else {
            const errorMsg: string = '<span>'+t('validation.invalidEmail')+'<br/>'+t('validation.correctEmailSyntax')+'</span>';
            const newField: Field = {value: form.email.value, error: errorMsg, isValid: false};
            newForm = {...newForm, ...{username: newField}};
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }

        if (Utilities.validNotEmptyValue(form.password.value)) {
            const newField: Field = {value: form.password.value, error: '', isValid: true};
            newForm = {...newForm, ...{password: newField}}
        } else {
            const errorMsg: string = '<span>'+t('validation.invalidPassword')+'</span>';
            const newField: Field = {value: form.password.value, error: '', isValid: false};
            newForm = {...newForm, ...{password: newField}};
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }

        setForm(newForm);
        return newForm.email.isValid && newForm.password.isValid;
    }

    /**
     * Generate Password
     */
    const generate = () => {
        let passwordELT: any;
        if (document.getElementById('password') !== null) {
            passwordELT = document.getElementById('password');
            passwordELT.focus();
        }
        let newForm: Form = form;
        const newField: Field = {value: Utilities.generatePassword(), error: '',isValid: true};
        newForm = {...newForm, ...{password: newField}}
        setForm(newForm);        
    }

    /**
     * Select Permission
     */
    const verifyEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setCheckEmail(checked);
    }

    /**
     * Update Manage User Grid
     */
    const notifyManageUserGrid = (user: User) => {
        updateManageUserGrid(user);
        setShowOverlay(false);
        setForm({
            email: {value: '', error:'', isValid: true},
            password: {value: '', error:'', isValid: true}
        });
        let message = `<span>${t('success.dataSavedSucessfully')}</span>`;
        Notification.showSuccess(message, constants.TIME_ERROR_NOTIFICATION);
    }

    /**
     * Add User to an application
     */
    const save = () => {
        const isValid = validateForm();
        if (isValid) {
            setShowOverlay(true);
            let user: User = new User(-1);
            user.email = form.email.value;
            user.password = form.password.value;     
            UserService.addUser(applicationId, user, checkEmail)
            .then(
                async response => {
                    user = await response.json();
                    if (!response.ok){
                        throw new Error(response.statusText);
                    }
                    notifyManageUserGrid(user);
                }
            ).catch(
                (error) => {
                    console.error("error", error);
                    let message =  error.message.toLowerCase() === "unauthorized" ? 
                    `<span>${t('error.noaccess')}</span>`:
                    `<span>
                        ${t('error.addUserMsg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')}
                    </span>`;
                    setShowOverlay(false);
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                }
            );
        }
    }

    /**
     * Add user and close the modal
     */
    const saveAndQuit = () => {
        const isValid = validateForm();
        if (isValid) {
            setShowOverlay(true);
            let user: User = new User(-1);
            user.email = form.email.value;
            user.password = form.password.value;     
            UserService.addUser(applicationId, user, checkEmail)
            .then(
                async response => {
                    user = await response.json();
                    if (!response.ok){
                        throw new Error(response.statusText);
                    }
                    notifyManageUserGrid(user);
                    clearModal();
                }
            ).catch(
                (error) => {
                    console.error("error", error);
                    let message =  error.message.toLowerCase() === "unauthorized" ? 
                    `<span>${t('error.noaccess')}</span>`:
                    `<span>
                        ${t('error.AddUserMsg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')}
                    </span>`;
                    setShowOverlay(false);
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                    clearModal();
                }
            );
        }
    }

    /**
     * Cancel Modal
     */
    const cancelModal = () => {
        setCheckEmail(false);
        setForm({
            email: {value: '', error:'', isValid: true},
            password: {value: '', error:'', isValid: true}
        });
        clearModal();
    }

    useEffect(() => {
        if (isModalOpen) {
            setCheckEmail(false);
            setForm({
                email: {value: '', error:'', isValid: true},
                password: {value: '', error:'', isValid: true}
            });
            let tooltipSearchRef = document.getElementById('tooltipped');
            let toolTipOption = {
                html: `<p>${t('application.sendCredentialUser')}</p>
                       <p>${t('application.cantPerformRegistration')}</p>`,
                position: 'right'
            };
            let modalElt = document.getElementById('userDetailsItemModal');
            let option = {
                dismissible: false
            };
            let modalInstance = M.Modal.init(modalElt, option);
            M.Tooltip.init(tooltipSearchRef, toolTipOption);
            modalInstance.open();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isModalOpen])

    return (
        <div>
            <div id="userDetailsItemModal" className={`modal modal-fixed-footer`}>
                <div className="modal-content">
                <LoadingOverlay
                    active={showOverlay}
                    spinner={<BounceLoader />}>
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h4 className="center-align">{t('modal.userDetailsItem.addUser')}</h4>
                            </div>
                        </div>
                        
                        <div className="row">
                            <form autoComplete="off">
                                <div className="row">
                                    <div className="input-field col s12">
                                        <label htmlFor="email">{t('label.email')} <span className="red-text text-accent-4">*</span></label>
                                        <input 
                                            id="email" 
                                            name="email" 
                                            type="text" 
                                            value={form.email.value}
                                            onChange={e => handleInputChange(e)}/>
                                    </div>

                                    <div className="input-field col s12">
                                        <label htmlFor="password">{t('label.password')} <span className="red-text text-accent-4">*</span></label>
                                        <input 
                                            id="password" 
                                            name="password" 
                                            type="password" 
                                            value={form.password.value}
                                            onChange={e => handleInputChange(e)}/>
                                            <span className="helper-text" >{t('validation.correctPassword')}</span>
                                    </div>

                                    <div className="col l3 m4 s12">
                                        <button type="button" className="waves-effect waves-light btn" onClick={generate}>
                                            {t('button.generate')}
                                        </button>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col s12">
                                        <label>
                                            <input type="checkbox" 
                                                className="filled-in"
                                                checked = {checkEmail}
                                                onChange={e => verifyEmail(e)} 
                                                />
                                            <span>{t('info.verifyEmail')}
                                                <i id="tooltipped" className="material-icons-outlined prefix">info</i>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </LoadingOverlay>
                </div>

                <div className="modal-footer">
                    <button type="button" className={`btn waves-effect waves-light teal lighten-2 ${!showOverlay ? "": " disabled"}`}
                            onClick={save}>
                        {t('button.save')} <i className="material-icons left">save</i>
                    </button>
                    &nbsp;&nbsp;
                    <button type="button" className={`btn waves-effect waves-light teal lighten-2 modal-close ${!showOverlay ? "": " disabled"}`}
                            onClick={saveAndQuit}>
                        {t('button.saveAndQuit')} <i className="material-icons left">forward</i>
                    </button>
                    &nbsp;&nbsp;
                    <button type="button" className={`btn waves-effect waves-light grey lighten-1 modal-close ${!showOverlay ? "": " disabled"}`}
                            onClick={cancelModal}>
                        {t('button.cancel')} <i className="material-icons left">close</i>
                    </button>
                </div>
            </div>
        </div>
        
    )
}

export default AddUserDetailsItemModal