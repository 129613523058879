import { DataGrid } from "devextreme-react";
import { Column, Editing, FilterRow, Format } from "devextreme-react/data-grid";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Utilities from "../helpers/utilities";
import Permission from "../models/permission";
import Role from "../models/role";
import Notification from "../helpers/notification";
import { constants } from '../models/constants';
import RoleService from "../services/role-service";

type Props = {
    role : Role
    applicationId: number, 
    overlay: Function
};
const RoleDetailsItem: FunctionComponent<Props> = ({role, applicationId, overlay}) => {

    const [dataSource, setDatasource] = useState<Permission[]>([]);

    const { t } = useTranslation();

    /**
     * Format Date
     * @param value 
     * @returns 
     */
    const formatDate = (value: Date) => {
        return Utilities.dateToString(value);
    }

    const showOverlay = (value: boolean) => {
        overlay(value);
    }

    const onPermissionUpdated = (value: any) => {
        showOverlay(true);
        RoleService.updatePermission(value.data, role.id, applicationId)
        .then(
            async response => {
                if (!response.ok){
                    throw new Error(response.statusText);
                }
                showOverlay(false);
                let message = ` <span>${t('success.permissionUpdate')}</span>`;
                Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {});
            }).catch(
                (error) => {
                    console.error("error", error);
                    showOverlay(false);
                    let message =  `<span>
                                        ${t('error.msg')}<br/> 
                                        ${t('error.msg1')}<br/>
                                        ${t('error.msg2')}
                                    </span>`;
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                    window.location.reload();
                }
            )
    }

    useEffect(() => {
        setDatasource(role.permissions);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [role.id]);

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col s12">
                        <DataGrid
                            dataSource={dataSource}
                            showBorders={true}
                            columnAutoWidth={true}
                            onRowUpdated={onPermissionUpdated}>
                            <Editing 
                                mode="cell"
                                allowUpdating={true}/>
                            <FilterRow visible={true}/>
                            <Column dataField="name" allowEditing={false} caption={t('common.permission')}/>
                            <Column dataField="description" caption={t('common.description')}/>
                            <Column 
                                dataField="createdDate"
                                allowEditing={false}
                                dataType="date"
                                caption={t('common.createdDate')}>
                                <Format formatter={formatDate}/>
                            </Column>
                            <Column 
                                dataField="lastModifiedDate"
                                allowEditing={false}
                                dataType="date" 
                                caption={t('common.lastModifiedDate')}>
                                <Format formatter={formatDate}/>
                            </Column>
                        </DataGrid>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default  RoleDetailsItem;