import React, { FunctionComponent, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ApplicationItem from "../components/application-item";
import ApplicationSearch from "../components/application-search";
import SubscribedApplication from "../models/subscribe-application";
import ApplicationService from "../services/application-service";
import Notification from "../helpers/notification";
import { SubscriptionStatus } from "../models/enum";
import ApplicationItemExpired from "../components/application-item-expired";
import ApplicationItemRejected from "../components/application-item-rejected";
import { useTranslation } from "react-i18next";
import ApplicationItemPending from "../components/application-item-pending";
import Loader from "../components/loader";
import { constants } from "../models/constants";

type Params = { ownerId: string };
const ApplicationOwnerList: FunctionComponent<RouteComponentProps<Params>> = ({ match }) => {
    const [subscribedApplications, setSubscribedApplication] = useState<SubscribedApplication[]>([]);
    const [errorState, setErrorState] = useState<String>("");
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [category, setCategory] = useState<SubscriptionStatus>(SubscriptionStatus.APPROVED);

    /**
     * Reload Subscribed applications
     * @param subscribedApplications 
     */
    const reloadOwnerApplication = (subscribedApplications: SubscribedApplication[]) => {
      setSubscribedApplication(subscribedApplications);
    }

    const { t } = useTranslation();

    /**
     * Show expired applications
     */
    const showExpiredApplication = () => {
      setShowOverlay(true);
      setCategory(SubscriptionStatus.EXPIRED);
      ApplicationService.getExpiredApplicationsByOwner(+match.params.ownerId)
        .then(
          async response => {
            const data: SubscribedApplication[] =  await response.json();
            if (!response.ok){
              throw new Error(response.statusText);
            }
            setShowOverlay(false);
            if (data.length === 0) {
              let message = `<span>
                              ${t('common.noexpired')}
                             </span>`;
              Notification.showWarning(message, 3000);
            } 
            setSubscribedApplication(data);
          }
        ).catch(
          (error) => {
              setShowOverlay(false);
              console.error("error", error);
              let message =  error.message.toLowerCase() === "unauthorized" ? 
                                `<span>
                                  ${t('error.noaccess')}
                                </span>`:
                                `<span>
                                  ${t('error.msg')} <br/> 
                                  ${t('error.msg1')}<br/>
                                  ${t('error.msg2')}
                                </span>`
              setErrorState(message);
              Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
          }
        );
    }

    /**
     * Show rejected applications
     */
    const showRejectedApplication = () => {
      setShowOverlay(true);
      setCategory(SubscriptionStatus.REJECTED);
      ApplicationService.getRejectedApplicationsByOwner(+match.params.ownerId)
        .then(
          async response => {
            const data: SubscribedApplication[] =  await response.json();
            if (!response.ok){
              throw new Error(response.statusText);
            }
            setShowOverlay(false);
            if (data.length === 0) {
              let message = `<span>
                              ${t('common.norejected')}
                             </span>`;
              Notification.showWarning(message, 3000);
            } 
            setSubscribedApplication(data);
          }
        ).catch(
          (error) => {
              setShowOverlay(false);
              console.error("error", error);
              let message =  error.message.toLowerCase() === "unauthorized" ? 
                `<span>
                  ${t('error.noaccess')}
                </span>`:
                `<span>
                  ${t('error.msg')} <br/> 
                  ${t('error.msg1')}<br/>
                  ${t('error.msg2')}
                </span>`
              setErrorState(message);
              Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
          }
        );
    }

    /**
     * Show approved application
     */
    const showApprovedApplication = () => {
      setShowOverlay(true);
      setCategory(SubscriptionStatus.APPROVED);
      ApplicationService.getApplicationsByOwner(+match.params.ownerId)
        .then(
          async response => {
            const data: SubscribedApplication[] =  await response.json();
            if (!response.ok){
              throw new Error(response.statusText);
            }
            setShowOverlay(false);
            if (data.length === 0) {
              let message = `<span>
                              ${t('common.novalid')}
                             </span>`;
              Notification.showWarning(message, 3000);
            } 
            setSubscribedApplication(data);
            
          }
        ).catch(
          (error) => {
            setShowOverlay(false);
            console.error("error", error);
            let message =  error.message.toLowerCase() === "unauthorized" ? 
              `<span>
                ${t('error.noaccess')}
              </span>`:
              `<span>
                ${t('error.msg')} <br/> 
                ${t('error.msg1')}<br/>
                ${t('error.msg2')}
              </span>`
              setErrorState(message);
              Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
          }
        );
    }
    /**
     * Show Pending application
     */
    const showPendingApplication = () => {
      setShowOverlay(true);
      setCategory(SubscriptionStatus.PENDING);
      ApplicationService.getPendingApplicationByOwner(+match.params.ownerId)
        .then(
          async response => {
            const data: SubscribedApplication[] =  await response.json();
            if (!response.ok){
              throw new Error(response.statusText);
            }
            setShowOverlay(false);
            if (data.length === 0) {
              let message = `<span>
                              ${t('common.nopending')}
                             </span>`;
              Notification.showWarning(message, 3000);
            } 
            setSubscribedApplication(data);
            
          }
        ).catch(
          (error) => {
            setShowOverlay(false);
            console.error("error", error);
            let message =  error.message.toLowerCase() === "unauthorized" ? 
              `<span>
                ${t('error.noaccess')}
              </span>`:
              `<span>
                ${t('error.msg')} <br/> 
                ${t('error.msg1')}<br/>
                ${t('error.msg2')}
              </span>`
              setErrorState(message);
              Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
          }
        );
    }

    /**
     * According to the subscription status, render relevant Application Item
     * @param app 
     * @returns 
     */
    const renderApplicationItem = (app: SubscribedApplication) => {
      switch (app.status) {
        case SubscriptionStatus.APPROVED: return <ApplicationItem key={app.id} application={app}/>;
        case SubscriptionStatus.EXPIRED: return <ApplicationItemExpired key={app.id} application={app}/>;
        case SubscriptionStatus.REJECTED: return <ApplicationItemRejected key={app.id} application={app}/>;
        case SubscriptionStatus.PENDING: return <ApplicationItemPending key={app.id} application={app}/>;

        default: return <ApplicationItem key={app.id} application={app}/>;
      }
    }

    useEffect(() => {

        setShowOverlay(true);
        ApplicationService.getApplicationsByOwner(+match.params.ownerId)
        .then(
          async response => {
            const data: SubscribedApplication[] =  await response.json();
            if (!response.ok){
              throw new Error(response.statusText);
            }
            setShowOverlay(false);
            setSubscribedApplication(data);
          }
        ).catch(
          (error) => {
              console.error("error", error);
              let message =  error.message.toLowerCase() === "unauthorized" ? 
              `<span>
                ${t('error.noaccess')}
              </span>`:
              `<span>
                ${t('error.msg')} <br/> 
                ${t('error.msg1')}<br/>
                ${t('error.msg2')}
              </span>`;
              setShowOverlay(false);
              setErrorState(message);
              Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
          }
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.ownerId]);

    return (
      <div>
      {
        errorState  === "" && 
        /** breadcumb section */
        <div className="container">
          <div className="row"></div>
            <div className="row">
              <div className="col s12 m12">
                <nav className="nav-wrapper teal">                
                  <a href="#!" className="breadcrumb" style={{marginLeft: '5px'}}>{t('breadcrumb.application')}</a>
                </nav>
              </div> 
            </div>
          </div>
        /** breadcumb section */
      }   
      {
        errorState  === "" && 
        <div className="container">
          <div className="row">
            <div className="col s12 m12">
              <p><u>{t('common.buttonLegend')}</u> : </p>
              <div className="row">
                <div className="col s12 l3 m3">
                  <span className="btn btn-small btn-floating">
                    <i className="material-icons">autorenew</i>
                  </span> {t('legend.autorenew')}
                </div>
                <div className="col s12 l3 m3">
                  <span className="btn btn-small btn-floating">
                    <i className="material-icons">post_add</i>
                  </span> {t('legend.setRoleAndPermission')}
                </div>
                <div className="col s12 l3 m3">
                  <span className="btn btn-small btn-floating">
                    <i className="material-icons">supervisor_account</i>
                  </span> {t('legend.manageUser')}
                </div>

                <div className="col s12 l3 m3">
                  <span className="btn btn-small btn-floating">
                    <i className="material-icons">assignment</i>
                  </span> {t('legend.configureRoleAndPermission')}
                </div>
                
              </div>
            </div>
          </div>
        </div>
      }
      {
        errorState === "" && 
        <div className="container">
          <div className="row">
            <div className="col s12">
              <p><u>{t('common.applicationCategory')}</u> : </p>
            </div>
          </div>
          <div className="row">
          <div className="col l3 m3 s12">
              <label>
                <input name="group" 
                       type="radio" 
                       className={`with-gap`}
                       disabled={showOverlay}
                       onClick={showPendingApplication}/>
                <span className="blue-text text-darken-4">
                {t('application.category.pending')} <i className="material-icons left">pending_actions</i>
                </span>
              </label>
            </div>
            <div className="col l3 m3 s12">
              <label className={`${!showOverlay?"":" disabled"}`}>
                <input name="group" 
                       type="radio"
                       disabled={showOverlay}
                       className={`with-gap`}
                       onClick={showExpiredApplication}
                       />
                <span className="red-text text-darken-4">
                  {t('application.category.expired')} <i className="material-icons left">timer</i>
                </span>
              </label>
            </div>
            <div className="col l3 m3 s12">
            <label>
                <input name="group" 
                       type="radio"
                       disabled={showOverlay}
                       className={`with-gap`}
                       onClick={showRejectedApplication}/>
                <span className="yellow-text text-darken-4">
                  {t('application.category.rejected')} <i className="material-icons left">remove_circle</i>
                </span>
              </label>
            </div>
            <div className="col l3 m3 s12">
              <label>
                <input name="group" 
                       type="radio" 
                       className={`with-gap`}
                       disabled={showOverlay}
                       onClick={showApprovedApplication}/>
                <span className="green-text text-darken-4">
                {t('application.category.approved')} <i className="material-icons left">check_box</i>
                </span>
              </label>
            </div>
          </div>
        </div>
      }
      {
        errorState  === "" && 
        <div className={`container`}>
          <div className="row">
              <div className="col s12 m6 l6">
                <ApplicationSearch subscriptionStatus={category} reload={reloadOwnerApplication} ownerId={match.params.ownerId}/>
              </div>
          </div>
          <div className="row application-item-css">
            {
              (subscribedApplications === undefined  || showOverlay) &&
              <h4 className="center"><Loader/></h4>
            }
            {
              subscribedApplications && !showOverlay &&
              subscribedApplications.length !== 0  && (subscribedApplications.map((application) => (
                renderApplicationItem(application)
              )))
            }
            {
              subscribedApplications && subscribedApplications.length === 0 &&
              !showOverlay &&
              (<h4 className="center">{t('common.noavailabledata')}</h4>)
            }
          </div>
        </div>
      } 
      </div>
    )
}
export default ApplicationOwnerList