import React, { FunctionComponent, useEffect } from "react";
//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
import Application from "../models/application";
import User from "../models/users.js";

type Props = {
    application: Application,
    filterByUser: Function
}

const UsersSearch: FunctionComponent<Props> = ({application, filterByUser}) => {

    /**
     * Filter list of users by email
     */
    const filterByEmail = (user: User) => {
        filterByUser(user);
    }

    //Initialize our Autocomplete component when componentDidMount
    useEffect(() => {

        let data: any = {};
        let mapdataToId: any = {};
        let limit = 5; 
        application.users.map((user) => {
            data[user.email] = null;
            mapdataToId[user.email] = user;
            return mapdataToId;
        });
        let userSearchElt = document.getElementById('autocomplete-user');
        let option = {
            data: data,
            limit: limit,
            minLength: 1,
            onAutocomplete: (e: string) => {
                filterByEmail(mapdataToId[e]);
            }
        };
            M.Autocomplete.init(userSearchElt, option);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])

    
    return (
        <form >
            <div className="input-field">
                <i className="material-icons prefix">search</i>
                <input type="search" id="autocomplete-user" placeholder="Look for an user" autoComplete="off" /> 
            </div> 
        </form>
        
    );
}
export default UsersSearch