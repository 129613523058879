import React, { FunctionComponent } from 'react';
  
const Loader: FunctionComponent = () => {
   
  return (
    <div className="progress"> 
      <div className="indeterminate"></div>
   </div> 
  );
}
   
export default Loader;