import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";

//@ts-ignore
import { Toolbar, Data } from "react-data-grid-addons";
//@ts-ignore
import ReactDataGrid from "react-data-grid";
import AssignPermissionToRole from "../models/assignPermToRole";
import { constants } from "../models/constants";

type Props = {
    displayAssignPermissionRoles: AssignPermissionToRole[],
};

const DisplayAssignPermissionRoleStepperItem: FunctionComponent<Props> = ({displayAssignPermissionRoles}) => {

    const selectors = Data.Selectors;
    const [filters, setFilters] = useState({});
    const [assignPermToRoleSource, setAssignPermToRoleSource] = useState<AssignPermissionToRole[]>([]);

    /**
     * Grid params for displaying permissions added by user
     */
     const gridMinHeight = constants.REACT_DATA_GRID_ADAZZLE_GRID_MIN_HEIGHT;
    const gridColumns = [
        { key: "role", name: "Role", width: 240, sortable: true, editable: false, filterable: true, resizable: true},
        { key: "permission", name: "Permission", width:370, sortable: true, editable: false, filterable: true, resizable: true}
    ]

    /**
     * Get rows
     * @param rows 
     * @param filters 
     */
    const getRows = (rows: AssignPermissionToRole[], filters: any) => {
        return selectors.getRows({ rows, filters });
    }

    const filteredRows = getRows(assignPermToRoleSource, filters);
    const [rows, setRows] = useState(filteredRows);

    /**
     * 
     * @param initialRows 
     * @param sortColumn 
     * @param sortDirection 
     */
    const sortRows = (initialRows : any, sortColumn: any, sortDirection: string) => (rows: any) => {

        const compare = (a: any, b: any) => {

            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(compare);
    }

    /**
     * Handle filter Change
     * @param filter 
     */
    const handleFilterChange = (filter: any) => (filters: any) => {
        const newFilters = {...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        return newFilters;
    }

    // Sort Grid
    const gridSort = (sortColumn: any, sortDirection: string) => {
        
        setRows(sortRows(filteredRows, sortColumn, sortDirection));
        return rows
    }

    useEffect(() => {
        let data = [...displayAssignPermissionRoles];
        setAssignPermToRoleSource(() => {

            let filteredRows = getRows(data, filters)
            return filteredRows;
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[displayAssignPermissionRoles])

    return (
        <ReactDataGrid
            columns={gridColumns}
            rowGetter={(i: number) => filteredRows[i]}
            rowsCount={filteredRows.length}
            minHeight={gridMinHeight}
            onGridSort={gridSort}
            toolbar={<Toolbar enableFilter={true} />}
            onAddFilter={(filter: any) => setFilters(handleFilterChange(filter))}
            onClearFilters={() => setFilters({})}
        />
    )
}

export default DisplayAssignPermissionRoleStepperItem;