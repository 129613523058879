import React, { FunctionComponent, useEffect, useState } from "react";
//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
import SubscribedApplication from "../models/subscribe-application";
import ApplicationService from "../services/application-service";
import Notification from "../helpers/notification";
import { SubscriptionStatus } from "../models/enum";
import { constants } from "../models/constants";
import { useTranslation } from "react-i18next";

type Props = {
    ownerId: string,
    reload: Function
    subscriptionStatus: SubscriptionStatus
};

const ApplicationSearch: FunctionComponent<Props> = ({ownerId, reload, subscriptionStatus}) => {
    const [searchValue, setSearchValue] = useState<string>('');
    // Handles input changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldValue: string = e.target.value;
        setSearchValue(fieldValue);
    }

    const { t } = useTranslation();

    /**
     * Search owner's application given the owner's id and the search expression
     */
    const searchOwnerApplicationByTerm = () => {
        reload(undefined);
        if (searchValue === "") {
            console.log('status', subscriptionStatus);
            ApplicationService.getApplicationsByOwnerAndStatus(+ownerId, subscriptionStatus)
            .then(
                async response => {
                    if (!response.ok){
                        throw new Error(response.statusText);
                    }
                    let message = "Success";
                    const data: SubscribedApplication[] =  await response.json();
                    Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, ()=>{
                        reload(data);
                    });
                }
        ).catch(
          (error) => {
                console.error("error", error);
                let message =  error.message.toLowerCase() === "unauthorized" ? 
                    `<span>${t('error.noaccess')}</span>`:
                    `<span>
                        ${t('error.msg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')}
                    </span>`;
                reload(undefined);
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
          }
        );
        } else {
            ApplicationService.searchApplication(searchValue, +ownerId, subscriptionStatus)
            .then(
            async response => {
                const data: SubscribedApplication[] =  await response.json();
                if (!response.ok){
                  throw new Error(response.statusText);
                }
                let message = "Success";
                Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, ()=>{
                    reload(data);
                });
              }
            ).catch(
                (error: any) => {
                    console.error("error", error);
                    let message =  error.message.toLowerCase() === "unauthorized" ? 
                        `<span>${t('error.noaccess')}</span>`:
                        `<span>
                            ${t('error.msg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')}
                        </span>`;   
                    reload(undefined);
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                }
            )
        }
    }

    //Initialize our Autocomplete component when componentDidMount
    useEffect(() => {
        let tooltipSearchRef = document.getElementById('tooltipped');
        let toolTipOption = {
            html: `<p>${t('common.quickSearchToolText')}</p>`,
            position: 'top'
        };
        M.Tooltip.init(tooltipSearchRef, toolTipOption)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <div>
            <table className="responsive-table">
                <tbody>
                    <tr>
                        <td>
                            <i id="tooltipped" className="material-icons-outlined prefix" >info</i>
                        </td>
                        <td>
                            <input 
                                type="text"
                                value={searchValue}
                                onChange={e => handleInputChange(e)}
                                id="search" 
                                placeholder="Quick Search" autoComplete="off" /> 
                        </td>
                        <td>
                            <button type="button" className="waves-effect waves-light btn" onClick={searchOwnerApplicationByTerm}>
                                <i className="material-icons left">search</i>{t('button.search')}
                            </button>
                        </td> 
                    </tr>
                </tbody>
            </table>
        </div>     
    );
}
export default ApplicationSearch