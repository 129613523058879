import React, {  useState } from "react";
import { FunctionComponent } from "react";
import Permission from "../models/permission";
import Notification from "../helpers/notification";
//@ts-ignore
import { Toolbar, Data } from "react-data-grid-addons";
//@ts-ignore
import ReactDataGrid from "react-data-grid";
import Utilities from "../helpers/utilities";
import { constants } from "../models/constants";
import { useTranslation } from 'react-i18next';

type Props = {
    nextStep : Function,
    permissionNotification: Function
};

type Field = {
    value: any,
    error: string,
    isValid?: boolean
}

type PermissionForm = {
    permission: Field,
    description: Field,
}

type UpdateRow = {
    fromRow: any;
    toRow: any;
    updated: any
}

const PermissionStepperItem: FunctionComponent<Props> = ({nextStep, permissionNotification}) => {

    const selectors = Data.Selectors;
    const { t } = useTranslation();
    const [filters, setFilters] = useState({});
    const [permissionSource, setPermissionSource] = useState<Permission[]>([]);
    const [permissionForm, setPermissionForm] = useState<PermissionForm>({
        permission: {value: '', error: '', isValid: true},
        description: {value: '', error:'', isValid: true},
    });

    /**
     * Grid params for displaying permissions added by user
     */

    const gridMinHeight = constants.REACT_DATA_GRID_ADAZZLE_GRID_MIN_HEIGHT;
    const permissioncolumns = [
        { key: "id", name: "Identifier", width:200, sortDescendingFirst: true, editable: false, sortable: true, filterable: false, resizable: true},
        { key: "name", name: "Permission", width:200, sortable: true, editable: true, filterable: true, resizable: true},
        { key: "description", name: "Description", width:200, sortable: true, editable: true, filterable: true, resizable: true},
        { key: "action", name: "Action", resizable: true, width:310}
    ]

    /**
     * 
     * @param column 
     * @param row 
     */
    const getCellActions = (column: any, row: any) => {
        const cellActions = [
            {
                icon: <div className="left"><button className="waves-effect waves-light btn btn-small red lighten-1">{t('button.delete')}</button></div>,
                callback: () => {
                    let data = [...permissionSource];
                    data = data.filter(e=> e.id !== row.id);
                    notifyPermissionObserver(data);
                    setPermissionSource(data);
                }
            }
        ]
        return column.key === "action" ? cellActions : null;
    };

    /**
     * Handle filter Change
     * @param filter 
     */
    const handleFilterChange = (filter: any) => (filters: any) => {
        const newFilters = {...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        return newFilters;
    }

    /**
     * Get rows
     * @param rows 
     * @param filters 
     */
    const getRows = (rows: Permission[], filters: any) => {
        return selectors.getRows({ rows, filters });
    }
    let filteredRows = getRows(permissionSource, filters);
    const [rows, setRows] = useState(filteredRows);

    /**
     * Function call after editing cell
     * @param param0 
     */
    const onGridRowsUpdated = ( { fromRow, toRow, updated }: UpdateRow ) => {
        let isValidName = true;
        let isValidDescription = true;
        const data = [...filteredRows];
        if (Object.keys(updated)[0] === 'name') {
            if(!/^PERMISSION_([a-zA-Z ]{1,10})$/.test(updated.name )) {
                const errorMsg: string = t('validation.invalidPermissionName');
                isValidName = false
                Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
            }
        } else {
            if (!Utilities.validNotEmptyValue(updated.description)) {
                const errorMsg: string = t('validation.invalidPermissionDescription');
                isValidDescription = false;
                Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
            }
        }

        if (isValidName && isValidDescription) {
            setPermissionSource(() => {
                const rows = data.slice();
                for (let i = fromRow; i <= toRow; i++) {
                    rows[i] = {...rows[i], ...updated };
                }
                notifyPermissionObserver(rows);
                return rows;
            });
        }
    }

    /**
     * Sort Rows
     * @param initialRows 
     * @param sortColumn 
     * @param sortDirection 
     */
    const sortRows = (initialRows : any, sortColumn: any, sortDirection: string) => (rows: any) => {
        const compare = (a: any, b: any) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(compare);
    }

    // Handles Subscribe Permission input changes
    const handlePermissionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.target.name;
        const fieldValue: string = e.target.value;
        const newField = {[fieldName]: { value: fieldValue }};
        //Using the spread operator we pass all newField props to permissionForm props
        setPermissionForm({...permissionForm, ...newField})
    }

    //Validate Form
    const validatePermssionForm = () => {
        let newForm: PermissionForm = permissionForm;
        // Validator Permission
        if(!/^PERMISSION_([a-zA-Z ]{1,10})$/.test(permissionForm.permission.value)) {
            const errorMsg: string = t('validation.invalidPermissionName');
            const newField: Field = {value: permissionForm.permission.value, error: errorMsg, isValid: false};
            newForm = {...newForm, ...{permission: newField}}
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        } else {
            const newField: Field = {value: permissionForm.permission.value, error: '', isValid: true};
            newForm = {...newForm, ...{permission: newField}}
        }

        if (Utilities.validNotEmptyValue(permissionForm.description.value)) {
            const newField: Field = {value: permissionForm.description.value, error: '', isValid: true};
            newForm = {...newForm, ...{description: newField}}
        } else {
            const errorMsg: string = t('validation.invalidPermissionDescription');
            const newField: Field = {value: permissionForm.description.value, error: errorMsg, isValid: false};
            newForm = {...newForm, ...{description: newField}}
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }

        setPermissionForm(newForm)
        return newForm.permission.isValid && newForm.description.isValid;
    };

    //Add Permission
    const addPermission = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isValid = validatePermssionForm();
        if (isValid) {
            const data = [...permissionSource];
            setPermissionSource(() => {
                let permission  = new Permission(0);
                permission.name = permissionForm.permission.value;
                permission.description = permissionForm.description.value;
                permission.id = data.length === 0 ? data.length + 1 : data[data.length - 1].id + 1;
                if (data.filter(e => e.name === permission.name).length === 0) {
                    data.push(permission);
                }
                filteredRows = getRows(data, filters)
                return filteredRows;

            });
            notifyPermissionObserver(data);
            setPermissionForm({
                permission: {value: '', error: '', isValid: true},
                description: {value: '', error:'', isValid: true},
            });
        }
    }

    /**
     * Notify Permission observers
     */
    const notifyPermissionObserver = (permissions: Permission[]) => {
        permissionNotification(permissions);
    }

    /**
     * Move to next step
     */
    const moveToNextStep = () => {
        nextStep(2);
    }

    // Sort Grid
    const gridSort = (sortColumn: any, sortDirection: string) => {
        setRows(sortRows(filteredRows, sortColumn, sortDirection));
        return rows
    }

    return (

        <div>
            <div className="step-title">{t('permissionStepper.stepper_1_title')}</div>
            <div className="container step-content">
                <div className="row">
                    <form onSubmit={e => addPermission(e)}>
                        <div className="input-field col s12 l4 m4">
                            <input 
                                id="permission"
                                name="permission"
                                type="text"
                                placeholder="Permission"
                                value={permissionForm.permission.value}
                                onChange={e => handlePermissionInputChange(e)}
                                autoComplete="off">
                            </input>
                            <span className="helper-text" >{t('permissionStepper.helperText')}</span>
                        </div>
                        <div className="input-field col s12 l4 m4">
                            <input 
                            id="description"
                            name="description"
                            type="text"
                            placeholder="Description"
                            value={permissionForm.description.value}
                            onChange={e => handlePermissionInputChange(e)}
                            autoComplete="off">
                            </input>
                        </div>

                        <div className="input-field col s12 l4 m4">
                            <button type="submit" className="waves-effect waves-light btn">
                                <i className="material-icons left">add</i>{t('button.add')}
                            </button>
                        </div>
                    </form>
                </div>
                
                <div className="row">
                    <div className="col s12 l9 m9">
                      <ReactDataGrid
                        columns={permissioncolumns}
                        rowGetter={(i: number) => filteredRows[i]}
                        rowsCount={filteredRows.length}
                        enableCellSelect={true}
                        onGridRowsUpdated={onGridRowsUpdated}
                        minHeight={gridMinHeight}
                        onGridSort={gridSort}
                        toolbar={<Toolbar enableFilter={true} />}
                        onAddFilter={(filter: any) => setFilters(handleFilterChange(filter))}
                        onClearFilters={() => setFilters({})}
                        getCellActions={getCellActions}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col s12 step-action">
                        {/** Button next will be visible only if at least one role has been added. */}

                        {
                            permissionSource && permissionSource.length > 0 &&
                            <button className="waves-effect waves-light btn" onClick={moveToNextStep}><i className="material-icons left">navigate_next</i>{t('navigation.stepperNext')}</button>
                        }   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PermissionStepperItem;