import Permission from "../models/permission";
import Role from "../models/role";

 
export default class RoleService {
 
  // Get Permissions for a specific role
  static getPermission(roleId: number): Promise<Response> {
    const authToken = sessionStorage.getItem('token')+'';
    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': authToken
        }
    };
    return fetch(`/ecauthenticateapi/workspace/roles/${roleId}/fetch/permissions`, requestOptions);
  }

  //Update role informations
  static updateRole(role: Role, id: number): Promise<Response> {
    const authToken = sessionStorage.getItem('token')+'';
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Authorization': authToken,
        'Content-type':'application/json'
      },
      body: JSON.stringify(role)
    };
    return fetch(`/ecauthenticateapi/workspace/application/${id}/update/role`, requestOptions);
  }

  //update permission informations
  static updatePermission (permission: Permission, roleId: number, id: number): Promise<Response> {
    const authToken = sessionStorage.getItem('token')+'';
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Authorization': authToken,
        'Content-type':'application/json'
      },
      body: JSON.stringify(permission)
    };
    return fetch(`/ecauthenticateapi/workspace/application/${id}/role/${roleId}/update/permission`, requestOptions);
  }

  /**
   * Delete permissions
   * @param permissions 
   * @param id 
   * @returns 
   */
  static deletePermissions (role: Role, id: number): Promise<Response> {
    const authToken = sessionStorage.getItem('token')+'';
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Authorization': authToken,
        'Content-type':'application/json'
      },
      body: JSON.stringify(role)
    };
    return fetch(`/ecauthenticateapi/workspace/application/${id}/permissions/delete`, requestOptions);
  }

  /**
   * Delete Role
   * @param role 
   * @param id 
   * @returns 
   */
  static deleteRole(role: Role, id: number): Promise<Response> {
    const authToken = sessionStorage.getItem('token')+'';
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Authorization': authToken,
        'Content-type':'application/json'
      },
      body: JSON.stringify(role)
    };
    return fetch(`/ecauthenticateapi/workspace/application/${id}/role/delete`, requestOptions);
  }

  /**
   * Add permission
   * @param role 
   * @param id 
   * @returns 
   */
  static addPermission (role: Role, id: number): Promise<Response> {
    const authToken = sessionStorage.getItem('token')+'';
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Authorization': authToken,
        'Content-type':'application/json'
      },
      body: JSON.stringify(role)
    };
    return fetch(`/ecauthenticateapi/workspace/application/${id}/permission/add`, requestOptions);
  }
}