/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import Notification from "../helpers/notification";
import Subscribe from "../models/subscribe";
import LoginService from "../services/login-service";
import SubscribeService from "../services/subscribe-service";
//@ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./home.css";
import Utilities from "../helpers/utilities";
import AuthService from "../services/auth-service";
import User from "../models/users";
import AdminService from "../services/admin-service";
import { constants } from "../models/constants";
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { useTranslation } from "react-i18next";
import BrokerService from "../services/broker-service";
/**
* Form Fields
*/
type Field = {
    value: any,
    isValid?: boolean
}

type ButtonProps = {
    value?: any,
    onClick?: any
}

/**
* Form object
*/
type LoginForm = {
    username: Field,
    password: Field
}

type SubscribeForm = {
    email: Field,
    name: Field,
    description: Field
}

type AdminForm = {
    email: Field,
    password: Field
}

const Home: FunctionComponent = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [loginForm, setLoginForm] = useState<LoginForm>({
        username: {value: '', isValid: true},
        password: {value: '', isValid: true}
    });
    const [subscribeForm, setSubscribeForm] = useState<SubscribeForm>({
        email: {value: '', isValid: true},
        name: {value: '', isValid: true},
        description: {value: '', isValid: true}
    });

    const [adminForm, setAdminForm] = useState<AdminForm>({
        email: {value: '', isValid: true},
        password: {value: '', isValid: true}
    });

    const tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(tommorow);
    const CalendarButtonInput = ({value, onClick}: ButtonProps) => (
        <a className="waves-effect waves-light btn btn-small" onClick={onClick}>
            <i className="material-icons left">calendar_today</i> {value} 
        </a>
    );
    
    // Change Start Date
    const onStartDateChange = (e: any) => {
        setStartDate(e);
    }

    // Change End Date
    const onEndDateChange = (e: any) => {
        setEndDate(e);
    }

    // Handles Login input change
    const handleLoginInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.target.name;
        const fieldValue: string = e.target.value;
        const newField = {[fieldName]: { value: fieldValue }};

        //Using the spread operator we pass all newField props to loginForm props
        setLoginForm({...loginForm, ...newField})
    };

    // Handles Subscribe input changes
    const handleSubscribeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const fieldName: string = e.target.name;
        const fieldValue: string = e.target.value;
        const newField = {[fieldName]: { value: fieldValue }};

        //Using the spread operator we pass all newField props to subscribeForm props
        setSubscribeForm({...subscribeForm, ...newField})
    }

    // Handles admin input changes
    const handleAdminInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.target.name;
        const fieldValue: string = e.target.value;
        const newField = {[fieldName]: { value: fieldValue }};
        //Using the spread operator we pass all newField props to subscribeForm props
        setAdminForm({...adminForm, ...newField})
    }

    /**
     * Validate Admin form
     * @param form 
     * @returns 
     */
    const validateAdminForm = (form: AdminForm) : boolean | undefined => {

        let newForm: AdminForm = form;
        if (Utilities.validEmail(adminForm.email.value)) {
            const newField: Field = {value: adminForm.email.value, isValid: true};
            newForm = {...newForm, ...{email: newField}}
        } else {
            const errorMsg: string = `${t('validation.invalidEmail')}<br/>${t('validation.correctEmailSyntax')}`;
            const newField: Field = {value: adminForm.email.value, isValid: false};
            newForm = {...newForm, ...{email: newField}};
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }

        if (Utilities.validNotEmptyValue(form.password.value)) {
            const newField: Field = {value: form.password.value, isValid: true};
            newForm = {...newForm, ...{password: newField}}
        } else {
            const errorMsg: string = `<span>${t('validation.invalidPassword')}</span>`;
            const newField: Field = {value: form.password.value, isValid: false};
            newForm = {...newForm, ...{password: newField}};
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }
        setAdminForm(newForm);
        return newForm.email.isValid && 
               newForm.password.isValid
    }

    /**
     * 
     * @param form Subscribe form validation
     */
    const validateSubscribeForm = (form: SubscribeForm) : boolean | undefined => {

        let newForm: SubscribeForm = form;
        if (Utilities.validEmail(subscribeForm.email.value)) {
            const newField: Field = {value: subscribeForm.email.value, isValid: true};
            newForm = {...newForm, ...{email: newField}};
        } else {
            const errorMsg: string = `<span>${t('validation.invalidEmail')}<br/>${t('validation.correctEmailSyntax')}</span>`;
            const newField: Field = {value: subscribeForm.email.value, isValid: false};
            newForm = {...newForm, ...{email: newField}};
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }

        if (Utilities.validNotEmptyValue(subscribeForm.name.value)) {
            const newField: Field = {value: subscribeForm.name.value, isValid: true};
            newForm = {...newForm, ...{name: newField}}
        } else {
            const errorMsg: string = `<span>${t('validation.invalidApplicationName')}</span>`;
            const newField: Field = {value: subscribeForm.name.value, isValid: false};
            newForm = {...newForm, ...{name: newField}};
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }

        if (Utilities.validNotEmptyValue(subscribeForm.description.value)) {
            const newField: Field = {value: subscribeForm.description.value, isValid: true};
            newForm = {...newForm, ...{description: newField}}
        } else {
            const errorMsg: string = `<span>${t('validation.invalidApplicationDescription')}</span>`;
            const newField: Field = {value: subscribeForm.description.value, isValid: false};
            newForm = {...newForm, ...{description: newField}};
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }

        setSubscribeForm(newForm);
        return newForm.email.isValid && 
               newForm.name.isValid &&
               newForm.description.isValid
    }


    /**
     * Login form validation
     */
    const validateLoginForm= (form: LoginForm) : boolean|undefined => {

        let newForm: LoginForm = form;
        if (Utilities.validEmail(loginForm.username.value)) {
            const newField: Field = {value: loginForm.username.value, isValid: true};
            newForm = {...newForm, ...{username: newField}};
        } else {
            const errorMsg: string = `<span>${t('validation.invalidEmail')}<br/>${t('validation.correctEmailSyntax')}</span>`;
            const newField: Field = {value: loginForm.username.value, isValid: false};
            newForm = {...newForm, ...{username: newField}};
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }

        if (Utilities.validNotEmptyValue(loginForm.password.value)) {
            const newField: Field = {value: loginForm.password.value, isValid: true};
            newForm = {...newForm, ...{password: newField}}
        } else {
            const errorMsg: string = `<span>${t('validation.invalidPassword')}</span>`;
            const newField: Field = {value: loginForm.password.value, isValid: false};
            newForm = {...newForm, ...{password: newField}};
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }
        setLoginForm(newForm);
        return newForm.username.isValid && newForm.password.isValid;
    }
    /**
     * Login
     */
    const login = ()  => {
        if (validateLoginForm(loginForm)) {
            setShowOverlay(true);
            LoginService.login(loginForm.username.value, loginForm.password.value).then(
                async response => {
                    const data =  await response.json();
                    if (!response.ok){
                        throw new Error(response.statusText);
                    }
                    if (data !== null && data.id !== -1) {
                        let message = `<span>${t('success.connectionSucceed')}</span>`;
                        AuthService.createContext(data.id, data.email, data.profile, data.roles);
                        sessionStorage.setItem('context', JSON.stringify(AuthService.getUserContext()));
                        Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {
                            if (Utilities.checkRole('ROLE_ADMIN') && Utilities.checkProfile('ADMIN')) {
                                sessionStorage.setItem('logged-in', data.email);
                                sessionStorage.setItem('logged-in-admin', data.email);
                                AuthService.auth();
                                AuthService.authAsAdmin();
                                BrokerService.fetchNotifications().then(
                                    async response => {
                                        let value = await response.json();
                                        if (!response.ok) {
                                            throw new Error(response.statusText);
                                        }
                                        sessionStorage.setItem('notification', value);
                                        history.push(`/subscribers`);
                                        window.location.reload();
                                    }
                                ).catch((error) => {console.error("error", error);});
                                
                            } else if (Utilities.checkRole('ROLE_OWNER') && Utilities.checkProfile('OWNER')) {
                                sessionStorage.setItem('logged-in-admin', '');
                                sessionStorage.setItem('logged-in', data.email);
                                sessionStorage.setItem('notification', '0');
                                AuthService.auth();
                                history.push(`owners/${data.id}/applications/`);
                                window.location.reload();
                            }
                            setShowOverlay(false);
                        });
                    } else {
                        let message = `<span>${t('error.connectionFailed')}</span>`;
                        Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                        setShowOverlay(false);
                    }
                }
            ).catch(
                (error) => {
                    console.error("error", error);
                    setShowOverlay(false);
                    let message =  `<span>
                                        ${t('error.loginMsg')}<br/> 
                                        ${t('error.msg1')}.<br/>
                                        ${t('error.msg2')}
                                    </span>`;
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                    //setShowOverlay(false);
                }
            )
        } 
    }

    /**
     * Register Admin
     */
    const registerAdmin = () => {
        if (validateAdminForm(adminForm)) {
            setShowOverlay(true);
            let user = new User(-1);
            user.email = adminForm.email.value;
            user.password = adminForm.password.value;
            AdminService.addAdmin(user).then(
                async response => {
                    await response.json();
                    if (!response.ok){
                        throw new Error(response.statusText);
                    }
                    let message = `${t('success.registerAdmin')}`;
                    Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {
                        window.location.reload();
                    });
                }
            ).catch(
                (error) => {
                    console.error("error", error);
                    let message =  `<span>
                                        ${t('error.adminMsg')} <br/> 
                                        ${t('error.msg1')}.<br/>
                                        ${t('error.msg2')}
                                    </span>`;
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                    setShowOverlay(false);
                }
            )
        }
    }

    /**
     * Subscribe
     */
    const subscribe = () => {
        if (validateSubscribeForm(subscribeForm)) {
            setShowOverlay(true);
            let subscribe = new Subscribe();
            subscribe.email = subscribeForm.email.value;
            subscribe.name = subscribeForm.name.value;
            subscribe.description = subscribeForm.description.value;
            subscribe.startDate  = Utilities.dateToString(startDate);
            subscribe.endDate  = Utilities.dateToString(endDate);
            SubscribeService.subscribe(subscribe).then(
                async response  => {
                    await response.json();
                    if (!response.ok){
                        throw new Error(response.statusText);
                    }
                    //let client = BrokerService.getBrokerClient();
                    //client.send("/broker/notify");
                    let message = `<span>${t('success.subscriptionRegistered')}</span>`
                    Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {
                        window.location.reload();
                    });
                }
            ).catch(
                (error) => {
                    console.error("error", error);
                    let message =  `<span>
                                        ${t('error.subscriptionMsg')}. <br/> 
                                        ${t('error.msg1')}.<br/>
                                        ${t('error.msg2')}
                                    </span>`;
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                    setShowOverlay(false);
                }
            )
        } 
    }

    return (

        <div>
            <div className="container">
                <div className="row"></div>
                <div className="row">
                    <div className="col s12 m10 offset-m2">
                        <nav className="nav-wrapper teal">
                            <a href="#!" className="breadcrumb" style={{marginLeft: '5px'}}>{t('common.home')}</a>
                        </nav>
                    </div>
                </div>
            </div>
            
            <div className="container">
                <div className="row">
                    <div className="col s12 m10 offset-m2">
                        <div className="card sticky-action teal lighten-5 z-depth-3 medium">
                            <div className="card-image">
                                <h4 className="flow-text center-align">{t('common.ecAuthAPI')}</h4>
                            </div>
                            <div className="card-content">
                                <span className="flow-text card-title center-align activator">
                                    {t('common.welcome')}
                                    
                                    <i className="material-icons right">more_vert</i>
                                </span>
                            </div>
                            <div className="flow-text card-action">
                                Thanks for your trust
                            </div>
                            <div className="card-reveal teal lighten-5">
                                <span className="flow-text card-title grey-text text-darken-4">{t('common.welcome')}
                                    <i className="material-icons right">close</i>
                                </span>

                                <p className="flow-text">EC Authenticate API is a tool that allows you to authenticate and authorize the users of your application.</p>
                                <p className="flow-text">If you want to use our services, please request for <b>an subscription</b>. We will get back to you instantly via your email address.</p>
                                <p className="flow-text">Once your application is registered with our services, </p>
                                <ol>
                                    <li className="flow-text">You can manage the roles of your users,</li>
                                    <li className="flow-text">Define new roles for your application / Remove existing roles,</li>
                                    <li className="flow-text">Define the permissions linked to each role.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Admin Modal */}
            
            <div id ="adminModal" className={`modal modal-fixed-footer`}>
            
                <div className="modal-content">
                    <LoadingOverlay
                        active={showOverlay}
                        spinner={<BounceLoader />}>
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h4 className="center-align">Register Admin</h4>
                            </div>
                        </div>
                        <div className="row">
                            <form autoComplete="off">
                                <div className="row">
                                    <div className="input-field col s12">
                                        <label htmlFor="emailAdmin">Email <span className="red-text text-accent-4">*</span></label>
                                        <input 
                                            id="emailAdmin" 
                                            name="email" 
                                            type="text" 
                                            value={adminForm.email.value}
                                            onChange={e => handleAdminInputChange(e)}/>
                                    </div>

                                    <div className="input-field col s12">
                                        <label htmlFor="passwordAdmin">Password <span className="red-text text-accent-4">*</span></label>
                                        <input 
                                            id="passwordAdmin" 
                                            name="password" 
                                            type="password" 
                                            value={adminForm.password.value}
                                            onChange={e => handleAdminInputChange(e)}/>
                                            <span className="helper-text" >{
                                                `Your password must contain at least 8 
                                                characters. At least one uppercase letter, one lowercase letter,
                                                numbers and special characters.`}</span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </LoadingOverlay>
                </div>
                
                <div className="modal-footer">
                
                    <button className={`btn waves-effect waves-light teal lighten-2 ${!showOverlay ? "": " disabled"}`}
                            onClick={!showOverlay ? registerAdmin : () => {}}>
                        Register Admin <i className="material-icons left">save</i>
                    </button>
                    &nbsp;&nbsp;
                    <button className={`btn waves-effect waves-light grey ighten-1 modal-close ${!showOverlay ? "": " disabled"}`}>
                        Cancel <i className="material-icons left">close</i>
                    </button>
                </div>
            </div>
            

            {/* Subscription Modal */}
            
            <div id ="modal" className={`modal modal-fixed-footer`}>
                <div className="modal-content">

                    <LoadingOverlay
                        active={showOverlay}
                        spinner={<BounceLoader />}>
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h4 className="center-align">Ask for a subscription</h4>
                            </div>
                        </div>
                        
                        <div className="row">
                            <form autoComplete="off">
                                <div className="row">
                                    <div className="input-field col s12 l4 m4">
                                        <label htmlFor="email">Email <span className="red-text text-accent-4">*</span></label>
                                        <input 
                                            id="email" 
                                            name="email" 
                                            type="text" 
                                            value={subscribeForm.email.value}
                                            onChange={e => handleSubscribeInputChange(e)}/>
                                    </div>

                                    <div className="col s12 l4 m4">
                                        <span>Subscription start date</span>
                                        <br/>
                                        <DatePicker
                                            dateFormat="dd MMM yyyy"
                                            selected={startDate}
                                            onChange={onStartDateChange}
                                            customInput={<CalendarButtonInput />}>
                                        </DatePicker>
                                    </div>

                                    <div className="col s12 l4 m4">
                                        <span>Subscription end date</span>
                                        <br/>
                                        <DatePicker
                                            dateFormat="dd MMM yyyy"
                                            selected={endDate}
                                            minDate={endDate}
                                            onChange={onEndDateChange}
                                            customInput={<CalendarButtonInput />}>
                                        </DatePicker>
                                    </div>

                                    <div className="input-field col s12">
                                        <label htmlFor="name">Application name<span className="red-text text-accent-4">*</span></label>
                                        <input 
                                            id="name" 
                                            name="name" 
                                            type="text" 
                                            value={subscribeForm.name.value}
                                            onChange={e => handleSubscribeInputChange(e)}/>
                                    </div>

                                    <div className="input-field col s12">
                                        <label htmlFor="description">Application description<span className="red-text text-accent-4">*</span></label>
                                        <input 
                                            id="description" 
                                            name="description" 
                                            type="text" 
                                            value={subscribeForm.description.value}
                                            onChange={e => handleSubscribeInputChange(e)}/>
                                    </div>

                                </div>                                  
                            </form>                            
                        </div>
                    </div>
                    </LoadingOverlay>
                </div>
                
                <div className="modal-footer">
                    <button className={`btn waves-effect waves-light teal lighten-2 ${!showOverlay ? "": " disabled"}`}
                            onClick={!showOverlay ? subscribe : () => {}}>
                        Send <i className="material-icons left">send</i>
                    </button>
                    &nbsp;&nbsp;
                    <button className={`btn waves-effect waves-light grey lighten-1 modal-close ${!showOverlay ? "": " disabled"}`}>
                        Cancel <i className="material-icons left">close</i>
                    </button>
                </div>
            </div>
            

            {/* Login Modal */}
            
            <div id ="loginModal" className={`modal modal-fixed-footer`}>
                <div className="modal-content">
                    <LoadingOverlay
                            active={showOverlay}
                            spinner={<BounceLoader />}>
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h4 className="center-align">Login</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                <form autoComplete="off">
                                    <div className="input-field col s12">
                                        <label htmlFor="username">Email <span className="red-text text-accent-4">*</span></label>
                                        <input id="username" name="username" 
                                               type="text" 
                                               value={loginForm.username.value} 
                                               onChange={e => handleLoginInputChange(e)} />
                                    </div>
                                    <div className="input-field col s12">
                                        <label htmlFor="password">Password<span className="red-text text-accent-4">*</span></label>
                                        <input id="password" 
                                               name="password" 
                                               type="password" 
                                               value={loginForm.password.value}
                                               onChange={e => handleLoginInputChange(e)} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </LoadingOverlay>
                </div>
                
                <div className="modal-footer">
                    <button id="loginBtn" className={`btn waves-effect waves-light teal lighten-2 ${!showOverlay ? "": " disabled"}`}
                            onClick={!showOverlay ? login : () => {}}>
                        Login <i className="material-icons left">login</i>
                    </button>
                    &nbsp;&nbsp;
                    <button className={`btn waves-effect waves-light grey lighten-1 modal-close ${!showOverlay ? "": " disabled"}`}>
                        Cancel <i className="material-icons left">close</i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Home