import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/default-light.css'
import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import SubscribeService from '../services/subscribe-service';
import { useHistory } from 'react-router-dom';
import './api-subscribers-list.css'
import Loader from '../components/loader';
import Notification from "../helpers/notification";
import SubscribeUser from '../models/subscribe-user';
import { constants } from '../models/constants';
import { useTranslation } from 'react-i18next';

const APISubscriberList: FunctionComponent = () => {

    const history = useHistory();
    const { t } = useTranslation();
    //React Grid columns
    const columns = [
        { name: 'id', header: 'Identifiant', sortable:false, type: 'number'},
        { name: 'email', header: 'Email', defaultFlex: 1 },
        { name: 'action', header: 'Action', minWidth: 200, 
        render: ({data}: any) => {

            const displayDetails = () => {
                history.push(`subscribers/${data.id}`);
            }
            return  <div style={{ display: 'inline-block' }}>
                        <button className="waves-effect waves-light btn btn-small" onClick={displayDetails}>View Details</button>
                    </div>
        }}
    ];
    
    const filterOptions = [
        { name: 'name', operator: 'startsWith', type: 'string', value: '' }
    ]
    const [showOverlay, setShowOverlay] = useState<boolean>(true);
    const gridStyle = { minHeight: 360 }
    const [dataSource, setDataSource] = useState<any>([]);

    useEffect(()=> {
        setShowOverlay(true);
        SubscribeService.getSubscribeUsers()
        .then(
            async response => {
                const users: SubscribeUser[] = await response.json();
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                setShowOverlay(false);
                let datasource: Array<any> = [];
                users.map(data => {
                    datasource.push(data);
                    return datasource;
                });
                setDataSource(datasource);
                
            }).catch(
                (error) => {
                    console.error("error", error);
                    setShowOverlay(false);
                    let message =  `<span>
                                        Something went wrong during processing the request. <br/> 
                                        Please try again.<br/>
                                        If issue is persisted. Please send us an email. Thanks
                                    </span>`;
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                }
            );
    },[]);

    return (
        <div>
            {/** Start breadcumb section */}
            <div className="container">
                <div className="row"></div>
                <div className="row">
                    <div className="col s12 m11 offset-m1">
                      <nav className="nav-wrapper teal">                
                        <a href="#!" className="breadcrumb" style={{marginLeft: '5px'}}>Home</a>
                      </nav>
                    </div> 
                </div>
            </div>
            {/** End breadcumb section */}

            <div className="container">
                {
                showOverlay &&
                <div className="col s12 m11 offset-m1">
                    <Loader/>
                </div> 
                } 
                {
                    !showOverlay &&
                    <div className="row">
                    {
                        dataSource && dataSource.length > 0 ? (
                            <div className="col s12 m11 offset-m1">
                                <h4 className="center-align">List of subscribers</h4>
                                <div className="row">
                                    <ReactDataGrid
                                        idProperty="id"
                                        enableSelection
                                        style={gridStyle}
                                        columns={columns}
                                        defaultFilterValue={filterOptions}
                                        dataSource={dataSource}/>
                                </div>
                            </div> 
                        ): (<h4 className="center">{t('common.noSubscribers')}</h4>)
                    }
                    </div>
                }
            </div>
        </div>
    )
}

export default APISubscriberList