
export default class AssignPermissionToRole {

    id: number;
    role: string;
    permission: string;
    createdDate: Date;
    lastModifiedDate: Date

    constructor(
        id: number,
        role: string = "",
        permission: string = "",
        createdDate: Date = new Date(),
        lastModifiedDate: Date = new Date()
    ) {

        this.id = id;
        this.role = role;
        this.permission = permission;
        this.lastModifiedDate = lastModifiedDate;
        this.createdDate = createdDate;
        
    }

    toJSON(){
        return {
            id: this.id,
            role: this.role,
            permission: this.permission,
        }
    }
}