import AuthenticatedUser from "../models/AuthenticatedUser";

export default class LoginService {

    // connect user to the application
    static login(username: string, password: string): Promise<Response> {

        let authUser = new AuthenticatedUser(username, password);
        const authToken = 'Basic ' + window.btoa(username + ":" +password);
        console.log('test');
        console.log(authToken);
        sessionStorage.setItem('token', authToken);
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': authToken,
                'Content-type':'application/json'
            },
            body: JSON.stringify(authUser)
        };
        
        return fetch(`/ecauthenticateapi/login/users`, requestOptions)
 
    }
}