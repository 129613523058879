import React from "react";
import { FunctionComponent } from "react";
import Loader from "./loader";
import RoleItem from "./role-item";
import './user-item.css';
import UsersSearch from "./users-search";
//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
import User from "../models/users";
import { useState } from "react";
import ApplicationService from "../services/application-service";
import SubscribedApplication from "../models/subscribe-application";
import { useTranslation } from "react-i18next";
import Utilities from "../helpers/utilities";


type Props = {
    application: SubscribedApplication
};

const UserItem: FunctionComponent<Props> = ({application}) => {

    let applicationRoles: Array<string> = [];
    let optionRole = {};
    const [user, setUser] = useState<User>(new User(0));
    const [users, setUsers] = useState<User[]>(application.users);
    const { t } = useTranslation();

    /**
     * Initialize Edit Modal
     * @param user 
     */
    const initializeEditModal = (user: User) => {
        ApplicationService.getApplication(application.id).then(
            async response => {
                const subscribedApplication: SubscribedApplication =  await response.json();
                if (!response.ok){
                  throw new Error(response.statusText);
                }
                subscribedApplication.roles.map((role) => {
                    applicationRoles.push(role.name)
                    return applicationRoles;
                });
                let roleElt = document.getElementById('chips');
                let data: any = {};
                applicationRoles.map(role => {
                    data[role] = null;
                    return applicationRoles
                })
                let options = Object.assign({}, data);
                optionRole = {
                    placeholder: `${t('common.rolePlaceholder')}`,
                    autocompleteOptions: {
                        data: options
                    }
                };
                M.Chips.init(roleElt, optionRole);
            }
        );
    }

    /**
     * Initialize Delete Modal
     * @param user 
     */
    const initializeDeleteModal = (user: User) => {
        ApplicationService.getApplication(application.id).then(
            async response => {
                const subscribedApplication: SubscribedApplication =  await response.json();
                if (!response.ok){
                  throw new Error(response.statusText);
                }
                subscribedApplication.roles.map((role) => {
                    applicationRoles.push(role.name)
                    return applicationRoles;
                });

                user.roles.map(role => {
                    applicationRoles  = applicationRoles.filter(applicationRole => role.name !== applicationRole);
                    return applicationRoles;
                });

                let roleElt = document.getElementById('deletechips');
                let data: any = [];
                user.roles.map((role) => {
                    let tag: any = {};
                    tag['tag'] = role.name
                    data.push(tag);
                    return data;
                })
                optionRole = {
                    data: data,
                    onChipDelete: (e: string) => {
                        preventDeletingUniqueRole();
                    }
                };
                M.Chips.init(roleElt, optionRole);
                preventDeletingUniqueRole();
            }
        );
    }


    /**
     * Prevent deleting unique role
     */
    const preventDeletingUniqueRole = ()=> {
        let roleElt = document.getElementById('deletechips');
        let instance = M.Chips.getInstance(roleElt);
        let data = instance.chipsData;
        if (data.length === 1) {
            let domElement = document.querySelector('#deletechips > div.chip');
            if (domElement !== null) {
                domElement.removeChild(domElement.getElementsByTagName('i')[0]);
            }                            
        }
    }

    /**
     * add new role
     * @param user 
     */
    const edit = (user: User) => {
        setUser(user);
        initializeEditModal(user);
        let modalElt = document.getElementById('modal');
        let option = {
            dismissible: false
        };
        let modalInstance = M.Modal.init(modalElt, option);
        modalInstance.open();
    }

    /**
     * Remove existing role
     * @param user 
     */
    const remove = (user: User) => {
        setUser(user);
        initializeDeleteModal(user);
        let modalElt = document.getElementById('deleteModal');
        let option = {
            dismissible: false
        };
        let modalInstance = M.Modal.init(modalElt, option);
        modalInstance.open();
    }

    /**
     * Move forward to Step Role
     * @param value 
     */
    const filterByEmail = (user: User) => {
        let data = users.filter(u => u.email === user.email);
        setUsers(data);
    }

    return (
        <div>
            <p>{t('common.assignOrDeleteUserRole')} <i className="blue-text text-darken-2">{application.name}</i></p>
            <div className="container">
                <div className="row">
                    <a href="#!" className="waves-effect waves-light btn btn-small">
                        <i className="material-icons" onClick={() => setUsers(application.users)}>refresh</i>
                        {t('button.reload')}
                    </a>
                </div>
            </div>
            <ul className="collection with-header">
                <li className="collection-header">
                    <UsersSearch key = {application.id} application = {application} filterByUser = {filterByEmail}/> 
                </li>
                {
                    users && users.length !==0 ?(users.map((user) => (
    
                        <li key = {user.id} className="collection-item avatar ">
                            <div>
                                {Utilities.toUpper(t('common.user'))}: <b>{user.email}</b>
                                <div className="secondary-content">
                                    <a href="#!" className="btn-floating btn-small" data-target="editButtons">
                                        <i className="material-icons" onClick={() => edit(user)}>mode_edit</i>
                                    </a>
                                    &nbsp; &nbsp;
                                    <a href="#!" className="btn-floating btn-small red" data-target="editButtons">
                                        <i className="material-icons" onClick={() => remove(user)}>delete</i>
                                    </a>
                                    
                                </div>
                            </div>                            
                            {Utilities.toUpper(t('common.roles'))}: <RoleItem key = {user.id} user = {user}/>
                        </li>
                        
                    ))) : (<h4 className="center"><Loader/></h4>)
                }
            </ul>

            {/* Edit Modal */}
            <div id ="modal" className="modal modal-fixed-footer">
                <div className="modal-content">
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h4 className="center-align">{t('common.assingRoleToAnUser')}</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                <input disabled id="email" value={user.email} type="text" className="form-control"/>
                                <div id="chips"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn waves-effect waves-light teal lighten-2">
                        {t('button.save')} <i className="material-icons left">save</i>
                    </button>
                    &nbsp;&nbsp;
                    <button className="btn waves-effect waves-light grey lighten-1 modal-close">
                        {t('button.cancel')} <i className="material-icons left">close</i>
                    </button>
                </div>
            </div>

            {/* Delete Modal */}
            <div id ="deleteModal" className="modal modal-fixed-footer">
                <div className="modal-content">
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h4 className="center-align">{t('common.deleteUserRole')}</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="email">{t('label.email')}</label>
                                        <input disabled value={user.email} type="text" className="form-control"/>
                                    </div>
                                    <label htmlFor="role">{t('common.roles')}</label>
                                    <div id="deletechips" className="form-group delete"></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn waves-effect waves-light teal lighten-2">
                        {t('button.save')} <i className="material-icons left">save</i>
                    </button>
                    &nbsp;&nbsp;
                    <button className="btn waves-effect waves-light grey lighten-1 modal-close">
                        {t('button.cancel')} <i className="material-icons left">close</i>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default UserItem;