import { FunctionComponent, useEffect, useState } from "react";
import Permission from "../models/permission";
import Role from "../models/role";
import User from "../models/users";
//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
import React from "react";
import DataGrid, { Button, Column, FilterRow, Format, Pager, Paging } from "devextreme-react/data-grid";
import { custom } from 'devextreme/ui/dialog';
import Notification from "../helpers/notification";
import Utilities from "../helpers/utilities";
import { Popup } from "devextreme-react";
import UserService from "../services/user-service";
import { constants } from "../models/constants";
import { useTranslation } from 'react-i18next';

//@ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'

type Props = {
    isModalOpen: boolean,
    clearModal: Function,
    reloadUserGrid: Function,
    applicationId: number, 
    user: User
};

type DisplayPermission = {
    perms: Permission[],
    visible: boolean
}
const DisplayUserRoleModalItem: FunctionComponent<Props> = ({
    isModalOpen, clearModal, reloadUserGrid, applicationId, user }) => {

    const [rolesource, setRoleSource] = useState<Role[]>([]);
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [displayPermission, setDisplayPermission] = useState<DisplayPermission>({perms: [], visible: false});
    const { t } = useTranslation();

    /**
     * View Permission
     */
    const viewPermission = (value: Role) => {
        let role: Role = value;
        let displayPopup: DisplayPermission = {perms: role.permissions, visible: true}
        setDisplayPermission(displayPopup);
    }

    /**
     * Update Manage User Grid
     */
     const notifyManageUserGrid = () => {
        reloadUserGrid();
    }

    /**
     * Revoke one Role
     * @param value 
     */
    const revokeRole= (value: Role) => {
        let roleToRevoke: Role[] = [];
        let confirmDialog  = custom({
            title: "Confirm",
            messageHtml: `<b>${t('common.confirmRevokeRole')}</b>`,
            buttons: [{
                text: `${t('button.yes')}`,
                icon:"remove",
                type:"danger",
                onClick: () => {
                    roleToRevoke.push(value);
                    setShowOverlay(true);
                    UserService.revokeRoles(user.id, applicationId, roleToRevoke)
                    .then(
                        async response => {
                            if (!response.ok){
                                throw new Error(response.statusText);
                            }
                            setShowOverlay(false);
                            let message = `${t('success.successfullyRevokeRole')}`;
                            Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, ()=>{
                                notifyManageUserGrid();
                            });
                            clearModal();
                        }
                    ).catch(
                        (error) => {
                            console.error("error", error);
                            let message =  error.message.toLowerCase() === "unauthorized" ? 
                            `<span>
                                ${t('error.noaccess')}
                            </span>`:
                            `<span>
                                ${t('error.revokingRoleMsg')} <br/> 
                                ${t('error.msg1')}<br/>
                                ${t('error.msg2')}
                            </span>`;
                            setShowOverlay(false);
                            Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION, ()=>{
                                notifyManageUserGrid();
                            });
                            roleToRevoke = [];
                            clearModal();
                        }
                    )
                }
            },{
                text: `${t('button.cancel')}`,
                onClick: () => {
                    roleToRevoke = [];
                }
            }]
        });
        confirmDialog.show();
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    const commandAction = (data: Role) => {
        let elt: any;
            elt =   <div>
                        <div>
                            <button 
                                className={`waves-effect waves-light btn btn-small ${data.permissions && data.permissions.length > 0 ? "": " disabled"}`} 
                                onClick={() => viewPermission(data)}>View permissions
                            </button>
                            &nbsp;&nbsp;
                            <button 
                                className={`waves-effect waves-light btn btn-small red lighten-1`} 
                                onClick={() => revokeRole(data)}>
                                {showOverlay && (
                                    <i className="material-icons left">refresh</i>
                                )}
                                {showOverlay && <span>Processing...</span>}
                                {!showOverlay && <span>Revoke Role</span>}
                            </button>
                        </div>
                        
                    </div>
        return elt;        
    }

    /**
     * Render popup template
     * @returns 
     */
    const popupTemplate = () => {
        return (
            displayPermission.perms && displayPermission.perms.map((permission: Permission) =>
                <p key={permission.id}><span>{permission.name}</span></p>        
            )    
        )
    }

    /**
     * Closing modal
     */
    const close = () => {
        clearModal();
    }

    /**
     * Format Date
     * @param value 
     * @returns 
     */
    const formatDate = (value: Date) => {
        return Utilities.dateToString(value);
    }

    /**
     * Hide popup
     */
    const hidePopup = () => {

        setDisplayPermission({perms: [], visible: false});
    }

    useEffect(() => {
        
        if (isModalOpen) {
            setRoleSource(user.roles);
            let modalElt = document.getElementById('displayUserRoleModalItem');
            let option = {
                dismissible: false
            };
            let modalInstance = M.Modal.init(modalElt, option);
            modalInstance.open();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isModalOpen])

    return (
        <div>
            
            <div id="displayUserRoleModalItem" className="modal modal-fixed-footer">
                <div className="modal-content">
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h4 className="center-align">Roles of user :  {user.email}</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                            <LoadingOverlay
                                active={showOverlay}
                                spinner={<BounceLoader />}>
                            <DataGrid
                                dataSource={rolesource}
                                showBorders={true}
                                columnAutoWidth={true}>
                                <FilterRow visible={true}/>
                                <Paging defaultPageSize={10}/>
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[5, 10, 15, 20]} 
                                    showInfo={true}/>
                                <Column dataField="id" caption="Role number"/>
                                <Column dataField="name"/>
                                <Column dataField="description" />
                                <Column
                                    dataField="createdDate" 
                                    dataType="date" 
                                    caption="Inserted Date">
                                    <Format formatter={formatDate}/>                
                                </Column>

                                <Column type="buttons" width={350} allowFiltering = {false}>
                                    <Button render={(value) => commandAction(value.data)} />
                                </Column>
                            </DataGrid>
                            </LoadingOverlay>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button className={`btn waves-effect waves-light grey lighten-1 modal-close`}
                        onClick={close}>
                        Cancel <i className="material-icons left">close</i>
                    </button>
                </div>
            </div>

            <Popup
                visible={displayPermission.visible}
                onHiding={hidePopup}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showTitle={true}
                title="Permissions"
                width={300}
                height={300}
                contentRender={popupTemplate}/>            
        </div>
    )
}

export default  DisplayUserRoleModalItem;