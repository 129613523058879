//@ts-ignore
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import React from "react";
import { FunctionComponent, useState } from "react";
import SubscribedApplication from "../models/subscribe-application";
//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
import { TextArea, Validator } from 'devextreme-react';
import { RequiredRule } from 'devextreme-react/data-grid';
import { Watermark } from '@hirohe/react-watermark';
import Utilities from '../helpers/utilities';
import ApplicationService from '../services/application-service';
import Notification from "../helpers/notification";
import { SubscriptionStatus } from '../models/enum';
import { useTranslation } from "react-i18next";
import { constants } from '../models/constants';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'


type Props = {
    application : SubscribedApplication
};

const ApplicationItemRejected: FunctionComponent<Props> = ({application}) => {

    const tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [showCalendar, setShowCalendar] = useState<boolean>(false);
    const [currentApplication, setCurrentApplication] = useState<SubscribedApplication>();
    const { t } = useTranslation();

    // Change Start Date
    const onStartDateChange = (e: any) => {
        setStartDate(e);
    }

    // Change End Date
    const onEndDateChange = (e: any) => {
        setEndDate(e);
    }

    // Open renew Subscription Modal
    const openRejectSubscriptionModal = (application: SubscribedApplication) => {
        setCurrentApplication(application);
        let modalElt = document.getElementById('rejectedSubscriptionModal');
        let option = {
            dismissible: false
        };
        let modalInstance = M.Modal.init(modalElt, option);
        modalInstance.open();
    }

    /**
     * Display or Hide Calendar
     */
    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
        if (!showCalendar) {
            setStartDate(new Date());
            setEndDate(tommorow);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    }

    /**
     * Handle Input change
     */
    const handleReopenedJustification = (e:any) => {
        application.reopenJustification = e.value;
    }

    // Reopen Contract
    const reopenContract = () => {
        if (currentApplication) {
            if (Utilities.validNotEmptyValue(currentApplication.reopenJustification)) {
                setShowOverlay(true);
                if (!showCalendar) {
                    if (startDate !== null) {
                        currentApplication.startDate = Utilities.dateToString(startDate);
                    }
                    if (endDate !== null) {
                        currentApplication.endDate = Utilities.dateToString(endDate);
                    }
                }
                currentApplication.status = SubscriptionStatus.PENDING;
                ApplicationService.setPending(currentApplication)
                .then(
                    async response => {
                        if (!response.ok){
                            throw new Error(response.statusText);
                        }
                        //let client = BrokerService.getBrokerClient();
                        //client.send("/broker/notify");
                        setShowOverlay(false);
                        let message = ` <span>${t('success.reopenSubscription')}</span>`;
                        Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {
                            window.location.reload();
                        });                 
                    }
                ).catch(
                    (error) => {
                        console.error("error", error);
                        let message =  `<span>
                                            ${t('error.reopenRejectedMsg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')}
                                        </span>`;
                        Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                        setShowOverlay(false);
                    }
                )
            } else {
                const errorMsg: string = `<span>${t('validation.reopeningJustification')}</span>`;
                Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
            }
        }

        
    }

    return (
        <div className="col m6 s12">
            <Watermark 
                text={t('watermark.rejected')}
                textColor="#f57f17">
                <div className="card teal lighten-5 z-depth-3">
                    <div className="card-content">
                        <span className="card-title">{application.name} </span>
                        <p>{application.description}</p>
                        <br/>
                        <p style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{application.appId}</p>
                    </div>
                    <div className="card-action">
                        <div>
                            <small>
                                {t('subscription.subscribedOn')} : {application.startDate !== '00/00/0000'?(application.startDate):'---'}
                            </small>
                        </div>
                        <div>
                            <small>
                                {t('subscription.expiredOn')} : {application.endDate !== '00/00/0000'?(application.endDate):'---'}
                            </small>
                        </div>
                        <span>&nbsp; &nbsp;</span>
                        <span className="btn btn-small btn-floating waves-effect waves-light">
                            <i onClick={()=> openRejectSubscriptionModal(application)} className="material-icons">edit</i>
                        </span>
                    </div>
                </div>
            </Watermark>
            
            {/* Rejection Subscription Modal */}
            
            <div id="rejectedSubscriptionModal" className={`modal modal-fixed-footer`}>
                <div className="modal-content">
                    <LoadingOverlay
                        active={showOverlay}
                        spinner={<BounceLoader />}>
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h4 className="center-align">{t('subscription.reopen')}</h4>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col m4 l4 s12">
                                <button id="modifyDate" className={`btn waves-effect waves-light teal lighten-2`}
                                    onClick={toggleCalendar}>
                                    {!showCalendar?t('button.updateSubscriptionPeriod'):t('button.leaveSubscriptionPeriod')}
                                </button>
                            </div>
                        </div>
                        {
                            showCalendar &&
                            <div className="row">
                                <div className="col l6 m6 s12">
                                    <span>{t('subscription.startDate')} :</span>
                                    <Calendar
                                        value={startDate}
                                        onChange={onStartDateChange}
                                        minDate = {new Date()}
                                        locale = {"en-EN"}>
                                    </Calendar>
                                </div>
                                <div className="col l6 m6 s12">
                                    <span>{t('subscription.endDate')} :</span>
                                    <Calendar
                                        value={endDate}
                                        onChange={onEndDateChange}
                                        minDate = {tommorow}
                                        locale = {"en-EN"}>
                                    </Calendar>
                                </div>
                            </div>
                        }
                        
                        <div className="row">
                            <div className="col s12">
                                <div className="dx-fieldset">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{t('subscription.rejectionReason')}</div>
                                        <TextArea
                                            className="dx-field-value"
                                            readOnly={true}
                                            height={190}
                                            maxLength={2000}
                                            defaultValue={application.reasonRejection}>
                                        </TextArea>
                                    </div>
                                </div>
                            </div>  
                        </div>

                        <div className="row">
                            <div className="col s12">
                                <div className="dx-fieldset">
                                    <div className="dx-field">
                                        <div className="dx-field-label">{t('subscription.reopeningJustification')}<span className="red-text text-accent-4">*</span></div>
                                        <TextArea
                                            className="dx-field-value"
                                            height={190}
                                            maxLength={2000}
                                            valueChangeEvent="keyup"
                                            onValueChanged={(e) => handleReopenedJustification(e)}>
                                            <Validator>
                                                <RequiredRule message={t('validation.reopeningJustification')} />
                                            </Validator>
                                        </TextArea>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                    </LoadingOverlay>
                </div>
                <div className="modal-footer">
                    <button id="rejectSubscriptionBtn" className={`btn waves-effect waves-light teal lighten-2 ${!showOverlay ? "": " disabled"}`}
                            onClick={reopenContract}>
                        {t('button.reopen')}
                    </button>
                    &nbsp;&nbsp;
                    <button className={`btn waves-effect waves-light grey ighten-1 modal-close ${!showOverlay ? "": " disabled"}`}>
                        {t('button.cancel')} <i className="material-icons left">close</i>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default  ApplicationItemRejected;