import React from "react";
import { FunctionComponent, useState } from "react";
import SubscribedApplication from "../models/subscribe-application";
//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
//@ts-ignore
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { Watermark } from '@hirohe/react-watermark';
import Notification from "../helpers/notification";
import ApplicationService from "../services/application-service";
import { useTranslation } from "react-i18next";
import Utilities from "../helpers/utilities";
import { constants } from "../models/constants";
import { SubscriptionStatus } from '../models/enum';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'


type Props = {
    application : SubscribedApplication
};

const ApplicationItemExpired: FunctionComponent<Props> = ({application}) => {

    const { t } = useTranslation();
    const tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(tommorow);
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [currentApplication, setCurrentApplication] = useState<SubscribedApplication>();


    // Change Start Date
    const onStartDateChange = (e: any) => {
        setStartDate(e);
    }

    // Change End Date
    const onEndDateChange = (e: any) => {
        setEndDate(e);
    }

    // Open renew Subscription Modal
    const openRenewSubscriptionModal = (application: SubscribedApplication) => {
        setCurrentApplication(application);
        let modalElt = document.getElementById('renewSubscriptionModal');
        let option = {
            dismissible: false
        };
        let modalInstance = M.Modal.init(modalElt, option);
        modalInstance.open();
    }

    // Renew Contract
    const renewContract = () => {
        setShowOverlay(true);
        if (currentApplication) {
            currentApplication.startDate = Utilities.dateToString(startDate);
            currentApplication.endDate = Utilities.dateToString(endDate);
            application.status = SubscriptionStatus.PENDING;
            ApplicationService.setPending(currentApplication).then(
                async response => {
                    if (!response.ok) {
                        throw new Error(response.statusText);
                    }
                    setShowOverlay(false);
                    let message = `<span>${t('success.reopenExpiredSubscription')}</span>`;
                    Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {
                        window.location.reload();
                    });                 
                }
                
            ).catch(
                (error) => {
                    console.log('error', error);
                    let message =  `<span> ${t('error.reopenExpiredMsg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')} </span>`;
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                    setShowOverlay(false);
                }
            )}
        
        }

    return (
        <div className="col m6 s12">
            <Watermark
                text={t('watermark.expired')}
                textColor="#b71c1c">
                <div className="card teal lighten-5 z-depth-3">
                    <div className="card-content">
                        <span className="card-title">{application.name}</span>
                        <p>{application.description}</p>
                        <br/>
                        <p style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{application.appId}</p>
                    </div>
                    <div className="card-action">
                        <div>
                            <small>
                                {t('subscription.subscribedOn')} : {application.startDate !== '00/00/0000'?(application.startDate):'---'}
                            </small>
                        </div>
                        <div>
                            <small>
                                {t('subscription.expiredOn')} : {application.endDate !== '00/00/0000'?(application.endDate):'---'}
                            </small>
                        </div>
                        <span>&nbsp; &nbsp;</span>
                        <span className="btn btn-small btn-floating waves-effect waves-light">
                            <i onClick={() => openRenewSubscriptionModal(application)} className="material-icons">autorenew</i>
                        </span>
                    </div>
                </div>
            </Watermark>
            

            {/* Renew Subscription Modal */}
            
            <div id="renewSubscriptionModal" className={`modal modal-fixed-footer`}>
                <div className="modal-content">
                    <LoadingOverlay
                        active={showOverlay}
                        spinner={<BounceLoader />}>
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h4 className="center-align">{t('common.renewSubscription')}</h4>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col s12 l5 m5">
                                <span>{t('subscription.startDate')} :</span>
                                <div className="row">
                                    <Calendar
                                        value={startDate}
                                        onChange={onStartDateChange}
                                        minDate = {new Date()}
                                        locale = {"en-EN"}>
                                    </Calendar>
                                </div>
                            </div>

                            <div className="col s12 l5 offset-l1 m5 offset-m1">
                                <span>{t('subscription.endDate')} :</span>
                                <div className="row">
                                    <Calendar
                                        value={endDate}
                                        onChange={onEndDateChange}
                                        minDate = {tommorow}
                                        locale = {"en-EN"}>
                                    </Calendar>
                                </div>
                            </div>
                        </div>
                    </div>
                    </LoadingOverlay>
                </div>
                <div className="modal-footer">
                    <button id="renewSubscriptionBtn" className={`btn waves-effect waves-light teal lighten-2 ${!showOverlay ? "": " disabled"}`}
                            onClick={renewContract}>
                        {t('button.renew')}
                    </button>
                    &nbsp;&nbsp;
                    <button className={`btn waves-effect waves-light grey lighten-1 modal-close ${!showOverlay ? "": " disabled"}`}>
                        {t('button.cancel')} <i className="material-icons left">close</i>
                    </button>
                </div>
            </div>
            
        </div>
        
    )
}

export default  ApplicationItemExpired;