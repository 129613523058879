import Role from "../models/role";

export default class ConfigurationService {

    /**
     * 
     * @param assignPermsToRole 
     */
    static saveAssignPermissionToRole(id: number, role: Role[]) {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-type':'application/json',
                'Authorization': authToken
            },
            body: JSON.stringify(role)
        };

        return fetch(`/ecauthenticateapi/workspace/applications/${id}/configurations/assignPermissionToRole`, requestOptions)
    }

    /**
     * 
     * @param id 
     * @returns 
     */
    static displayAssignPermissionRoles(id: number) {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-type':'application/json',
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/workspace/applications/${id}/configurations/getRoleAndPermission`, requestOptions)
    }
}