import Role from "../models/role";
import User from "../models/users";

export default class UserService {

    /**
     * Id refers to the Id of the application.
     * @param id 
     * @returns 
     */
    static fetch(id: number): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/workspace/applications/${id}/fetch/users`, requestOptions);
    }

    /**
     * Fetch roles
     * @param id 
     * @returns 
     */
    static fetchRoles(id: number): Promise<Response> {

        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };

        return fetch(`/ecauthenticateapi/workspace/applications/${id}/fetch/roles`, requestOptions);
    }

    /**
     * Grants User Roles
     * @param userId 
     * @param applicationId 
     */
    static grantRoles(userId: number, id: number, roles: Role[]): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify(roles),
            headers: {
                'Content-type':'application/json',
                'Authorization': authToken
            }
        };

        return fetch(`/ecauthenticateapi/workspace/applications/${id}/grant/roles/users/${userId}`, requestOptions);
    }

    /**
     * Revokes roles
     * @param userId 
     * @param id 
     */
    static revokeRoles(userId: number, id: number, roles: Role[]): Promise<Response> {

        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'PUT',
            body: JSON.stringify(roles),
            headers: {
                'Content-type':'application/json',
                'Authorization': authToken
            }
        };

        return fetch(`/ecauthenticateapi/workspace/applications/${id}/revoke/roles/users/${userId}`, requestOptions);
    }

    /**
     * Add User
     * @param applicationId 
     * @param user 
     * @param checkEmail 
     */
    static addUser(applicationId: number, user: User, checkEmail: Boolean): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
                'Content-type':'application/json',
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/workspace/applications/${applicationId}/save/users?checkEmail=`+checkEmail, requestOptions);
    }

    /**
     * Find User
     * @param id 
     * @returns 
     */
    static findUser(id: number): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/users/${id}`, requestOptions);
    }
}