import React, { useEffect } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { FunctionComponent, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import SubscribedApplication from "../models/subscribe-application";
import SubscribeService from "../services/subscribe-service";
import Loader from "../components/loader";
import Notification from "../helpers/notification";
import './api-subscribers-detail.css'
import UserService from "../services/user-service";
import User from "../models/users";
import { Button, Popup, TabPanel, TextArea, Validator } from "devextreme-react";
//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
import DataGrid, { Column, FilterRow, Pager, Paging, RequiredRule } from "devextreme-react/data-grid";
import ApplicationService from "../services/application-service";
import { Editing } from "devextreme-react/tree-list";
import Utilities from "../helpers/utilities";
import AdminService from "../services/admin-service";
import { useTranslation } from "react-i18next";
import { constants } from "../models/constants";
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import BrokerService from "../services/broker-service";

type Params = {id: string};

const APISubscribersListDetail: FunctionComponent<RouteComponentProps<Params>> = ({match}) => {

    const history = useHistory();
    const [rejectionReason, setRejectionReason] = useState("");
    const [reopenJustification, setReopenJustification] = useState("");
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [isPopupJustificationVisible, setPopupJustificationVisible] = useState(false);
    const [subscriber, setSubscriber] = useState<string>("");
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<SubscribedApplication[]>([]);
    const [expiredSubscription, setExpiredSubscription] = useState<SubscribedApplication[]>([]);
    const [selectedSubscribeApplication, setSelectedSubscribeApplication] = useState<SubscribedApplication[]>([]);
    const [rejectedSubscriptionApplication, setRejectedSubscriptionApplication] = useState<SubscribedApplication[]>([]);
    const gridStyle = { minHeight: 300 };
    const cellStyle = (cellProps: { value: any; }) => {
        const {value} = cellProps; 
        return {
            color: value === 'Expired' ? '#FF0000' : 
                   value === 'In Progress' ? '#008000' : 
                   value === 'To validate' ? '#0000CD' : '#FFA500'
        }
    }

    const columns = [
        { name: 'id', header: 'Identifiant', sortable:false, type: 'number' },
        { name: 'name', header: 'Application', type: 'string', minWidth: 200, maxWidth: 500 },
        { 
            name: 'status', 
            header: 'Status', 
            type: 'string',
            minWidth: 200, 
            maxWidth: 500,
            style: cellStyle
        },
        { name: 'startDate', header: 'Start Date', type: 'date' },
        { name: 'endDate', header: 'End Date', type: 'date'},
        { name: 'initialPeriod', header: 'Period Left', type:'string' },
        { name: 'reasonRejection', header: 'Rejection reason', type: 'string', minWidth: 200, defaultFlex: 4,
        render: ({data}: any) => {
            
            const showReason = (data: SubscribedApplication) => {
                setRejectionReason(data.reasonRejection)
                setPopupVisibility(!isPopupVisible);
            }
            return  <div style={{ display: 'inline-block' }}>
                        {
                            (data.status === 'Rejection' || 
                            (data.status === 'To validate' && data.reopenJustification !== "")) &&  
                            <button className="waves-effect waves-light btn btn-small" onClick={() => showReason(data)}>Show reason</button>
                        }
                    </div>
        }},
        { name: 'reopenJustification', header: 'Reopen Justification', type: 'string', minWidth: 200, defaultFlex: 4,
        render: ({data}: any) => {
            
            const showReopenJustification = (data: SubscribedApplication) => {
                setReopenJustification(data.reopenJustification)
                setPopupJustificationVisible(!isPopupJustificationVisible);
            }
            return  <div style={{ display: 'inline-block' }}>
                        {
                            data.status === 'To validate' &&
                            data.reopenJustification !== "" &&
                            <button className="waves-effect waves-light btn btn-small" onClick={() => showReopenJustification(data)}>Show Justification</button>
                        }
                    </div>
        }},
    ];

    const filterOptions = [
        { name: 'name', operator: 'startsWith', type: 'string', value: '' },
        { name: 'initialPeriod', operator: 'startsWith', type: 'string', value: ''},
        { name: 'durationUnit', operator: 'startsWith', type: 'string', value: ''},
        { name: 'status', operator: 'startsWith', type: 'string', value: ''}
    ]

    /*
     * 
     */
    const togglePopup = () => {
        setPopupVisibility(!isPopupVisible);
    }

    const justificationTogglePopup = () => {
        setPopupJustificationVisible(!isPopupJustificationVisible);
    }

    const cancelledSubscriptionModal = () => {
        window.location.reload();
    }

    const { t } = useTranslation();

    /**
     * Render content after clicking on button show reason
     */
     const renderReasonContent = () => {
        let elt: any;
        elt =   <div className="dx-fieldset">
                    <TextArea
                        readOnly={true}
                        height={190}
                        maxLength={2000}
                        defaultValue={rejectionReason}>
                    </TextArea>
                </div>
        return elt;
    }

    const renderJustificationContent = () => {
        let elt: any;
        elt =   <div className="dx-fieldset">
                    <TextArea
                        readOnly={true}
                        height={190}
                        maxLength={2000}
                        defaultValue={reopenJustification}>
                    </TextArea>
                </div>
        return elt;
    }
    // Action done when selecting row.
    const onSelectedSubscribeApplication = (body: any) => {
        let applications: SubscribedApplication[] = [];
        let keys = Object.keys(body.selected);
        keys.forEach(key => {
            applications.push(body.selected[key])
        })
        setSelectedSubscribeApplication(applications);
    };

    /**
     * Go Back to the list of subscribers
     */
    const goBackToSubscribers = () => {
        history.replace(`/subscribers`);
    }

    /**
     * open rejection subscription modal
     */
    const openRejectionSubscriptionModal = () => {
        let modalElt = document.getElementById('rejectSubscriptionModal');
        let option = {
            dismissible: false
        };
        let modalInstance = M.Modal.init(modalElt, option);
        modalInstance.open();
    }

    /**
     * open subscription rejection modal
     */
    const openExpiredSubscriptionModal = () => {
        let modalElt = document.getElementById('expiredSubscriptionModal');
        let option = {
            dismissible: false
        };
        let modalInstance = M.Modal.init(modalElt, option);
        modalInstance.open();
    }

    /**
     * Save rejection reason
     * @param e 
     */
    const saveReason = (e: any, data: SubscribedApplication) => {
        let result = e.validationGroup.validate();
        if(result.isValid) {
            let array = [...rejectedSubscriptionApplication];
            array.push(data);
            setRejectedSubscriptionApplication(array);
            let message = `<span>${t('success.rejectionReason')}</span>`;
            Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {
                setShowOverlay(false);
            });
        }
    }
    /**
     * Save Rejected Application
     */
    const saveToDatabase = () => {
        if (Utilities.collectionIsNotEmpty(rejectedSubscriptionApplication)) {
            setShowOverlay(true);
            AdminService.updateApplication(rejectedSubscriptionApplication)
            .then (
            async response => {
                if (!response.ok){
                    throw new Error(response.statusText);
                }
                let message = `<span>${t('success.dataSavedSucessfully')}</span>`;
                Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {
                    setShowOverlay(false);
                    window.location.reload();
                });
            }
            ).catch (
            (error) => {
                console.error("error", error);
                let message =  `<span>
                                    ${t('error.rejectionMsg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')}
                                </span>`;
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                setShowOverlay(false);
            }
            )
        } else {
            let message =  `<span>
                                ${t('common.mandatoryRejectedReason')}
                            </span>`;
            Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
        }
    }

    /**
     * Extended Expired Subscription
     */
    const updateExpiredSubscriptionGrid = (array: SubscribedApplication[]) => {
        setShowOverlay(true);
        ApplicationService.extendedSubscriptionPeriod(array)
        .then(
            async response => {
                if (!response.ok){
                    throw new Error(response.statusText);
                }
                let message = `<span>${t('success.subscriptionPeriodExtended')}</span>`;
                Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {
                    setShowOverlay(false);
                });

            }
        ).catch(
            (error) => {
                console.error("error", error);
                let message =  `<span>
                                    ${t('error.extendedExpiredMsg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')}
                                </span>`;
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                setShowOverlay(false);
            }
        )
    }
    /**
     * Callback when Subscription end period has been updated
     */
    const onExtendedPeriodUpdated = (subscribedAppplication: any) => {
        let array: SubscribedApplication[] = [];
        if (subscribedAppplication !== undefined) {
            let data = subscribedAppplication[0].data;
            if (Utilities.compareDate(data.startDate, data.endDate)) {
                data.endDate  = Utilities.stringToDate(data.endDate);
                expiredSubscription.map(value => {
                    if (value.id === data.id) {
                        value.endDate = data.endDate;
                        array.push(value);
                    }
                    return value;
                })
            } else {
                let message = `<span>${t('common.dateContrainst')}</span>`;
                Notification.showWarning(message, constants.TIME_WARNING_NOTIFICATION);
            }
            if (array.length !== 0) {
                updateExpiredSubscriptionGrid(array);
            }
        }
    }

    /**
     * Callback function called when cancelling editing mode.
     * @param e 
     */
    const onExtendedPeriodCancelled = () => {
        expiredSubscription.map(data => {
            //Check if end Date is after start Date
            if (Utilities.compareDate(data.startDate, data.endDate)) {
                data.endDate  = Utilities.stringToDate(data.endDate);
            } else {
                let message = `<span>${t('common.dateContrainst')}</span>`;                
                Notification.showWarning(message, constants.TIME_WARNING_NOTIFICATION);
            }
            return data;
        });
    }
    /**
     * Handle value change
     * @param e 
     * @param data 
     */
    const handleValueChange = (e: any, data: SubscribedApplication)=> {
        console.log('e', e.value);
        let newValue = e.value;
        data.reasonRejection = newValue;
    }

    /**
     * render tab panel title
     * @param data 
     * @returns 
     */
    const renderTabPanelTitle = (data: SubscribedApplication) => {
        let elt: any;
        elt =   <div>
                    <span>{data.name}</span>
                </div>
        return elt;
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    const renderItem = (data: SubscribedApplication) => {
        let elt: any;
        elt =   <div>
                    <div className="dx-fieldset">
                        <div className="dx-fieldset-header">{t('common.provideRejectionReason')}<span className="red-text text-accent-4">*</span></div>
                    </div>
                    <div className="dx-fieldset">
                    <TextArea
                        height={190}
                        maxLength={2000}
                        defaultValue={data.reasonRejection}
                        valueChangeEvent="keyup"
                        onValueChanged={(e) => handleValueChange(e, data)}>
                        <Validator>
                            <RequiredRule message={t('common.reasonRequired')} />
                        </Validator>
                    </TextArea>
                    </div>
                    
                    <div className="right dx-fieldset">
                        <Button
                            disabled={showOverlay}
                            text="Save"
                            icon="save"
                            type="success"
                            onClick={(e) => saveReason(e, data)}
                        />
                    </div>
                </div>
        return elt;
    }

    /**
     * Validate list of subscriptions
     */
    const validate = () => {
        setShowOverlay(true);
        ApplicationService.validate(selectedSubscribeApplication.map(e => e.id))
        .then(
            async response => {
                let application: SubscribedApplication[] = await response.json();
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                setShowOverlay(false);
                setDataSource(application);
                let message = `<span>${t('success.validationDone')}</span>`;
                //let client = BrokerService.getBrokerClient();
                Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {
                    window.location.reload();
                });
            }
        ).catch(
            (error) => {
                setShowOverlay(false);
                console.error("error", error);
                let message =  `<span>
                                    ${t('error.validationSubscriptionMsg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')}
                                </span>`;
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
            }
        )
    }

    useEffect(()=> {
        UserService.findUser(+match.params.id)
        .then(
            async response => {
                const data: User = await response.json();
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                setSubscriber(data.email);
                SubscribeService.getSubscribeApplication(+match.params.id)
                .then(
                    async response => {
                        let subscribedApplications: SubscribedApplication[] = await response.json();
                        if (!response.ok) {
                            throw new Error(response.statusText);
                        }   
                        setDataSource(subscribedApplications);
                        SubscribeService.getExpiredSubscribeApplications(+match.params.id)
                        .then(
                            async response => {
                                let subscribedApplications: SubscribedApplication[] = await response.json();
                                if (!response.ok) {
                                    throw new Error(response.statusText);
                                }
                                setExpiredSubscription(subscribedApplications);
                                BrokerService.fetchNotifications().then(
                                    async response => {
                                        let value = await response.json();
                                        if (!response.ok) {
                                            throw new Error(response.statusText);
                                        }
                                        sessionStorage.setItem('notification', value);
                                    }
                                ).catch((error) => {console.error("error", error);});
                            }
                        ).catch(
                            (error) => {
                                console.error("error", error);
                                let message =  `<span>
                                                    ${t('error.msg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')}
                                                </span>`;
                                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                            }
                        )
                    }
                ).catch(
                (error) => {
                    console.error("error", error);
                    let message =  `<span>
                                        ${t('error.msg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')}
                                    </span>`;
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                }
                );
            }
        ).catch(
            (error) => {
                console.error("error", error);
                let message =  `<span>
                                    ${t('error.msg')}<br/>${t('error.msg1')}<br/>${t('error.msg2')}
                                </span>`;
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
            }
        );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <div>
            {/** Start breadcumb section */}
            <div className="container">
                <div className="row"></div>
                <div className="row">
                    <div className="col s12 m11 offset-m1">
                      <nav className="nav-wrapper teal">                
                        <a href="#!" className="breadcrumb" style={{marginLeft: '5px'}} onClick={goBackToSubscribers}>List of subscribers</a>
                        <a href="#!" className="breadcrumb">Details</a>
                      </nav>
                    </div> 
                </div>
            </div>
            {/** End breadcumb section */}

            <div className="container">
                
                <div className="row">
                    {
                    !showOverlay && dataSource && dataSource.length > 0 ? (
                    <div className="col s12 m11 offset-m1">
                        {
                            subscriber && <h4 className="center-align">Subscription details: {subscriber}</h4>
                        }
                        <div className="row">
                            {
                            selectedSubscribeApplication && selectedSubscribeApplication.length > 0 &&
                            <button id="done" className="btn waves-effect waves-light teal lighten-2" onClick ={validate}>
                                Validate <i className="material-icons left">done</i>
                            </button>
                            }
                            &nbsp;&nbsp;&nbsp;
                            {
                            selectedSubscribeApplication && selectedSubscribeApplication.length > 0 &&
                            <button id="delete" className="btn waves-effect waves-light red lighten-2" onClick={openRejectionSubscriptionModal}>
                                Reject <i className="material-icons left">delete</i>
                            </button>
                                        
                            }
                            &nbsp;&nbsp;&nbsp;
                            {
                                expiredSubscription && expiredSubscription.length > 0 &&
                                <button id="delete" className="btn waves-effect waves-light blue lighten-2" onClick={openExpiredSubscriptionModal}>
                                    Expired Subscriptions <i className="material-icons left">timer</i>
                                </button>
                            }
                            
                        </div>
                                
                        <div className="row">
                            <ReactDataGrid
                            style={gridStyle}
                            enableSelection
                            checkboxColumn
                            checkboxOnlyRowSelect
                            onSelectionChange={(e) => onSelectedSubscribeApplication(e)}
                            columns={columns}
                            defaultFilterValue={filterOptions}
                            emptyText="No data..."
                            dataSource={dataSource} />
                        </div>
                    </div>):(<h4 className="center"><Loader/></h4>)
                    }
                </div>
            </div>

            {/** Rejection Subscription Modal */}
            
            <div id="rejectSubscriptionModal" className={`modal modal-fixed-footer`}>
                <div className="modal-content">
                    <LoadingOverlay
                        active={showOverlay}
                        spinner={<BounceLoader />}>
                    <div className="container">
                        <div className="row">
                            <TabPanel
                                dataSource={selectedSubscribeApplication}
                                scrollByContent={true}
                                scrollingEnabled={true}
                                itemTitleRender={(data) => renderTabPanelTitle(data)}
                                itemRender={(data) => renderItem(data)}
                            />
                        </div>
                    </div>
                    </LoadingOverlay>
                </div>
                <div className="modal-footer">
                    <button 
                        className={`btn waves-effect waves-light teal lighten-2 ${Utilities.collectionIsNotEmpty(rejectedSubscriptionApplication)? " modal-close": ""}`}
                        onClick={saveToDatabase}>
                        {t('button.submit')} <i className="material-icons left">done</i>
                    </button>
                    &nbsp;&nbsp;
                    <button className={`btn waves-effect waves-light grey lighten-1 modal-close`}
                            onClick={cancelledSubscriptionModal}>
                        {t('button.cancel')}
                    </button>
                </div>
            </div>
            
            <div id="expiredSubscriptionModal" className={`modal modal-fixed-footer`}>
                <div className="modal-content">
                    <LoadingOverlay
                        active={showOverlay}
                        spinner={<BounceLoader />}>
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h5 className="center-align">Expired Subscriptions for: {subscriber}</h5>
                            </div>
                        </div>
                        <div className="row">
                           <DataGrid
                            dataSource={expiredSubscription}
                            disabled={showOverlay}
                            showBorders={true}
                            columnAutoWidth={true}
                            onSaved={(data) => onExtendedPeriodUpdated(data.changes)}
                            onEditCanceled={onExtendedPeriodCancelled}>
                                <FilterRow visible={true} applyFilter={'auto'}/>
                                <Paging defaultPageSize={10}/>
                                <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[5, 10, 15, 20]} 
                                showInfo={true}/>
                                <Editing 
                                mode="row"
                                allowUpdating={true}/>
                                <Column 
                                    dataField="name" 
                                    caption="Application" 
                                    allowEditing={false}/>
                                <Column 
                                    dataField="startDate" 
                                    caption="Start Date" 
                                    allowEditing={false}
                                    dataType="date"
                                    format="dd MMM yyyy"/>
                                <Column 
                                    dataField="endDate" 
                                    caption="End Date"
                                    dataType="date"
                                    format="dd MMM yyyy"/>
                            </DataGrid> 
                        </div>
                    </div>
                    </LoadingOverlay>
                </div>
                <div className="modal-footer">
                    <button className={`btn waves-effect waves-light grey lighten-1 modal-close`}
                            onClick={cancelledSubscriptionModal}>
                        {t('button.close')}
                    </button>
                </div>
            </div>
            
            <Popup
                showTitle={true}
                contentRender={renderReasonContent}
                width={500}
                height={500}
                title="Reason for rejection"
                resizeEnabled={true}
                visible={isPopupVisible}
                dragEnabled={true}
                position="center"
                onHiding={togglePopup}/>

            <Popup
                showTitle={true}
                contentRender={renderJustificationContent}
                width={500}
                height={500}
                title="Reason for reopen"
                resizeEnabled={true}
                visible={isPopupJustificationVisible}
                dragEnabled={true}
                position="center"
                onHiding={justificationTogglePopup}/>    
        </div>
    )
}
export default APISubscribersListDetail