import Permission from "./permission";

export default class Role {
    id: number;
    name: string;
    description: string
    permissions: Permission[];
    createdDate: Date;
    lastModifiedDate: Date
         
    constructor(
        id: number,
        name: string = "...",
        description: string = "...",
        permissions: Permission[] = [],
        createdDate: Date = new Date(),
        lastModifiedDate: Date = new Date()
    ) {

        this.id = id;
        this.name = name;
        this.description = description;
        this.permissions = permissions;
        this.lastModifiedDate = lastModifiedDate;
        this.createdDate = createdDate;
        
    }

    /*toJSON(){
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            permissions: this.permissions,
            createdDate: this.createdDate,
            lastModifiedDate: this.lastModifiedDate
        }
    }*/
}