/**
 * Backend Subscription class.
 */
export default class Subscribe {
    email: string;
    name: string;
    description: string;
    startDate: string;
    endDate: string;

    constructor(
        email: string = "default@mail.com",
        name: string = "",
        description: string = "",
        startDate: string = "00/00/0000",
        endDate: string = "00/00/0000"
    ) {
        this.email = email;
        this.name = name;
        this.description = description;
        this.startDate = startDate;
        this.endDate = endDate;
    }
    toJSON(){
        return {
            email: this.email,
            name: this.name,
            description: this.description,
            startDate: this.startDate,
            endDate: this.endDate
        }
    }
}