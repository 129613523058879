import Role from "./role";

export default class User {

    id: number;
    email: string;
    password: string;
    creationDate: Date;
    lastModifiedDate: Date
    roles: Role[];

    constructor(
        id: number,
        email: string = "default@gmail.com",
        password: string = "",
        creationDate: Date = new Date(),
        lastModifiedDate: Date = new Date(),
        roles: Role[] = []
    ) {
        this.id = id;
        this.email = email;
        this.password = password;
        this.creationDate = creationDate
        this.lastModifiedDate = lastModifiedDate
        this.roles = roles;
    }
}