import { SubscriptionStatus } from "../models/enum";
import SubscribedApplication from "../models/subscribe-application";

export default class ApplicationService {

    // Get applications by owner
    static getApplicationsByOwner(ownerId: number): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/workspace/${ownerId}/applications`, requestOptions);
    }

    //Get application by owner and status
    static getApplicationsByOwnerAndStatus(ownerId: number, status: SubscriptionStatus): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/workspace/${ownerId}/applications/${status}`, requestOptions);
    }

    // Get expired applications by owner 
    static getExpiredApplicationsByOwner(ownerId: number): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/workspace/${ownerId}/applications/expired`, requestOptions);
    }

    // Get rejected applications by owner 
    static getRejectedApplicationsByOwner(ownerId: number): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/workspace/${ownerId}/applications/rejected`, requestOptions);
    }

    // Get pending applications by owner
    static getPendingApplicationByOwner(ownerId: number): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/workspace/${ownerId}/applications/pending`, requestOptions);
    }

    //Get application
    static getApplication(id: number): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/workspace/applications/${id}`, requestOptions);
    }

    /**
     * List of subscriptions to validate
     */
    static validate(ids: Array<number>): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': authToken,
                'Content-type':'application/json'
            },
            body: JSON.stringify(ids)
        };
        return fetch(`/ecauthenticateapi/admin/manage/subscription`, requestOptions);
    }

    // Search application
    static searchApplication(searchExpression: string, ownerId: number, status: SubscriptionStatus): Promise<Response> {

        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/workspace/owner/${ownerId}/applications/${status}/search?term=`+searchExpression, requestOptions);
    }
    
    // update application
    static setPending(application: SubscribedApplication): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': authToken,
                'Content-type':'application/json'
            },
            body: JSON.stringify(application)
        };
        return fetch(`/ecauthenticateapi/workspace/application/set/pending`, requestOptions);
    }

    //extended subscription period
    static extendedSubscriptionPeriod(applications: Array<SubscribedApplication>): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': authToken,
                'Content-type':'application/json'
            },
            body: JSON.stringify(applications)
        };
        return fetch(`/ecauthenticateapi/admin/manage/subscriptions/extended/expired`, requestOptions);
    }
}