import moment from 'moment';
import Permission from '../models/permission';
import Role from '../models/role';
import UserContext from '../models/userContext';
import AuthService from '../services/auth-service';


export default class Utilities {

    /**
     * 
     * @param date Convert Date to String
     */
    static dateToString(date: Date): string {
        return moment(date).format('DD MMM YYYY');
    }

    static stringToDate(date: string): string {
        return moment(date).format('DD MMM YYYY');
    }

    static toUpper(value: string) {
        return value.toUpperCase;
    }

    static toLower(value: string) {
        return value.toLowerCase;
    }

    /**
     * Comparing Start Date and End Date
     * @param startDate 
     * @param endDate 
     * @returns 
     */
    static compareDate(startDate: string, endDate: string) {
        let start = moment(startDate).format('DD MMM YYYY');
        let end = moment(endDate).format('DD MMM YYYY');
        return moment(end).isAfter(moment(start))
    }
    /**
     * 
     * @param email 
     */
    static validEmail(email: string | null): boolean {

        if (null === email) {
            return false;
        }
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    static collectionIsNotEmpty(collection: Array<any>) {
        return collection.length !== 0;
    }

    /**
     * 
     * @param value 
     */
    static validNotEmptyValue(value: string | null): boolean {
        if (null === value) {
            return false;
        }
        return  value !== undefined && 
                value !== null && 
                value !== '' && 
                value.length !== 0
    }

    /**
     * Check specific role
     * @param roles 
     */
    static checkRole(value: string): boolean {
        let context: UserContext =  AuthService.getUserContext();
        return context.roles.find((role: Role) => role.name === value)!== undefined
    }

    /**
     * Check user's profile
     * @param value 
     * @returns 
     */
    static checkProfile(value: string): boolean {
        let context: UserContext =  AuthService.getUserContext();
        return context.profile === value;
    }

    /**
     * Check specific permission
     * @param value 
     */
    static checkPermission(value: string): boolean {
        let context: UserContext =  AuthService.getUserContext();
        return context.roles.find((role: Role) => 
            role.permissions.find((permission: Permission) => permission.name === value)
        )!== undefined
    }

    static getRandomInteger (min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    static shuffle(value: string): string {

        let arr = value.split(',');
        let finalValue: string="";
        finalValue = arr.sort(function () {
            return  0.5-Math.random()
        }).join('');

        return finalValue;
    }

    /**
     * Generate Password
     */
    static generatePassword(): string {

        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz[!@#$%^&*(),?:{}|<>]0123456789';
        let upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let lowerCase = 'abcdefghijklmnopqrstuvwxyz';
        let number = '0123456789';
        let specialCharacters = '[!@#$%^&*(),?:{}|<>]';

        let passwordLength = 4;
        let password = '';

        for(let i = 0; i < passwordLength; i++) {
            password +=upperCase[this.getRandomInteger(0, upperCase.length - 1)]
        }
        
        for(let i = 0; i < passwordLength; i++) {
            password +=lowerCase[this.getRandomInteger(0, lowerCase.length - 1)]
        }

        for(let i = 0; i < passwordLength; i++) {
            password +=specialCharacters[this.getRandomInteger(0, specialCharacters.length - 1)]
        }

        for(let i = 0; i < passwordLength; i++) {
            password +=number[this.getRandomInteger(0, number.length - 1)]
        }

        for(let i = 0; i < passwordLength; i++) {
            password +=characters[this.getRandomInteger(0, characters.length - 1)]
        }

        password = this.shuffle(password);
        return password;
    }

    static convertToNumber(value: string): number {
        return Number(value)
    }
}