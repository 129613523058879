import React, { FunctionComponent, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import ApplicationOwnerList from './pages/application-owner-list';
import PageNotFound from './pages/page-not-found';
import PrivateRoute from './PrivateRoute';
 import ApplicationOwnerDetail from './pages/application-owner-detail';
import Home from './pages/home';
import AuthService from './services/auth-service';
//@ts-ignore
import M from '../node_modules/materialize-css/dist/js/materialize.min.js';
import logout from './helpers/logout';
import APISubscriberList from './pages/api-subscribers-list';
import APISubscribersListDetail from './pages/api-subscribers-detail';
import SetRoleAndPermission from './pages/setRoleAndPermission';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import ManageUser from './pages/manage-user';
import "./app.css";
import { constants } from './models/constants';
import UserContext from './models/userContext';
import { useTranslation } from 'react-i18next';
import SessionTimer from './components/session-timer';
import DisplayConfiguration from './pages/display-configuration';

const App: FunctionComponent = () => {

    const { t } = useTranslation();
    window.onbeforeunload = () => {
        localStorage.removeItem('logged-in');
        localStorage.removeItem('token');
    }
    let loggedIn = localStorage.getItem('logged-in');
    let sessionLogin = sessionStorage.getItem('logged-in');
    if (!loggedIn) {
        if (sessionLogin) {
            AuthService.auth();
            if (sessionLogin === 'admin@gmail.com') {
                AuthService.authAsAdmin();
            }
            localStorage.setItem('logged-in', sessionLogin);
        }
    }

    /**
     * Open modal for Subscription to EC AUTHENTICATE API
     */
    const openSubscribeModal = () => {
        let modalElt = document.getElementById('modal');
        let option = {
            dismissible: false
        };
        let modalInstance = M.Modal.init(modalElt, option);
        modalInstance.open();
    }

    /**
     * open for log in to the appplication
     */
    const openLoginModal = () => {
        let modalElt = document.getElementById('loginModal');
        let option = {
            dismissible: false
        };
        let modalInstance = M.Modal.init(modalElt, option);
        modalInstance.open();
    }

    let contextString = sessionStorage.getItem('context');
    let applicationURL = undefined;
    if (contextString !== null) {
        let userContext: UserContext = JSON.parse(contextString);
        applicationURL = `/owners/${userContext.id}/applications/`
    }

    
    useEffect(() => {

        //Check if any subscription exists
        if (AuthService.isAuth()) {
            let dropdownElt = document.getElementById('dropdown-trigger');
            let option = {
                coverTrigger: false,
                constrainWidth: false
            };
            M.Dropdown.init(dropdownElt, option);
            
        }
    }, []);

    return (
    <Router>
        <div className="main-wrapper">
            {
                (AuthService.isAuth() || AuthService.isAdmin())  &&
                <ul id="dropdown1" className="dropdown-content">
                    {
                        <li><a href="#!" onClick= {logout} >Log out</a></li>
                    }
                    {
                        AuthService.getLoggedUser() === constants.ADMIN_LOGIN &&
                        <li>
                            <a href="/subscribers">Subscribers</a>
                        </li>
                    }
                    {
                        AuthService.getLoggedUser() !== constants.ADMIN_LOGIN &&
                        <li>
                            <a href={`${applicationURL}`}>Applications</a>
                        </li>
                    }
                    
                </ul>
            }
            <nav>
            <div className="nav-wrapper teal">
                <Link to="/" className="brand-logo left" style={{marginLeft: '3px'}}>EcAuth-API</Link>

                    {
                        (!AuthService.isAuth() && !AuthService.isAdmin()) &&
                        <ul id="nav-mobile" className="right">
                            <li>
                                <a href="#!" onClick = {openSubscribeModal}>
                                    Ask for a subscription
                                </a>
                            </li>
                            <li><a href="#!" onClick = {openLoginModal}>Login</a></li>
                        </ul>
                    }
                    {
                        /*AuthService.isAuth() &&
                        <SessionTimer />*/
                    }
                    {
                        AuthService.isAuth() &&
                        <ul id="nav-mobile" className="right">
                            {
                                AuthService.getLoggedUser() === constants.ADMIN_LOGIN && 
                                AuthService.notification() !== null && Number(AuthService.notification()) > 0 &&
                                <li>
                                    <a href="#!">
                                        {
                                            Number(AuthService.notification()) <= 30 &&
                                            <span className="new badge red">{Number(AuthService.notification())}</span>
                                        }
                                        {
                                            Number(AuthService.notification()) > 30 &&
                                            <span className="new badge red">30+</span>
                                        }
                                    </a> 
                                </li>
                            }
                            <li>
                                <a id="dropdown-trigger" href="#!" data-target="dropdown1">
                                {AuthService.getLoggedUser()}<i className="material-icons right">arrow_drop_down</i>
                                </a>
                            </li>
                        </ul>
                    }                
            </div>
            </nav>
            {/** Route manager */}
            <main>
            <Switch>
                <Route exact path = "/" component={Home}/>
                <PrivateRoute exact path = "/subscribers" component={APISubscriberList}/>
                <PrivateRoute exact path = "/subscribers/:id" component={APISubscribersListDetail}/>
                <PrivateRoute exact path="/owners/:ownerId/applications/:applicationId/manage/users" component={ManageUser}/>
                <PrivateRoute exact path="/owners/:ownerId/applications" component={ApplicationOwnerList}/>
                <PrivateRoute exact path="/owners/:ownerId/applications/:applicationId" component={ApplicationOwnerDetail}/>
                <PrivateRoute path="/owners/:ownerId/applications/:applicationId/configurations" component={SetRoleAndPermission}/>
                <PrivateRoute path="/owners/:ownerId/applications/:applicationId/display/configurations" component={DisplayConfiguration}/>
                <Route component={PageNotFound}></Route>
            </Switch>
            </main>
            
        </div>
        <div className="page-footer">
            {t('copyright.version')}
        </div>
    </Router>
 )
}
  
export default App;