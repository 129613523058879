import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import Permission from "../models/permission";
import Role from "../models/role";
//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
import { useTranslation } from "react-i18next";
import { Popup } from "devextreme-react";
import { constants } from "../models/constants";
import Notification from "../helpers/notification";
import Utilities from "../helpers/utilities";
import { custom } from "devextreme/ui/dialog";
import RoleService from "../services/role-service";
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'


type Props = {
    isManagePermission: boolean,
    applicationId: number,
    isModalOpen: boolean,
    clearModal: Function,
    updatedGrid: Function,
    currentRole : Role,
    permissions : Permission[]
};

type Field = {
    value: any,
    error: string,
    isValid?: boolean
}

type PermissionForm = {
    permission: Field,
    description: Field,
}

const AssignPermissionModal: FunctionComponent<Props> = ({
    isModalOpen, 
    isManagePermission,
    applicationId,
    clearModal, 
    updatedGrid, 
    currentRole, 
    permissions})=> {
       
    const { t } = useTranslation();
    const [modalPermissions, setModalPermissions] = useState<Permission[]>([]);
    const [popupVisible, setPopupVisible] = useState<boolean>(false);
    const [showOverLay, setShowOverLay] = useState<boolean>(false);
    const [permissionForm, setPermissionForm] = useState<PermissionForm>({
        permission: {value: '', error: '', isValid: true},
        description: {value: '', error:'', isValid: true},
    });
    /**
     * Select Permission
     */
    const onChange = (data: Permission, e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        if (checked) {
            currentRole.permissions.push(data);
        } else {
            currentRole.permissions = currentRole.permissions.filter((currentPermission) => currentPermission.name !== data.name);
        }
    }

    /**
     * call back function for updating the grid
     */
    const updateGrid = (action: string) => {
        updatedGrid(currentRole, action);
        currentRole.permissions = [];
        setModalPermissions(() => {
            let modalPermissions: Permission[] =[];
            return modalPermissions;
        });
        clearModal();
    }

    /**
     * Display Configuration Updated Grid
     */
    const displayConfigurationUpdateGrid = () => {
        updatedGrid()
    }

    /**
     * Add new Permission to existing list
     */
    const createPermission = () => {
        setPopupVisible(true);
    }

    /**
     * Delete new permission
     */
    const deletePermission = () => {
        if (currentRole.permissions.length !== 0) {
            if (currentRole.permissions.length === modalPermissions.length) {
                let message = `${t('common.roleShouldHaveAtLeastOnePermission')}`;
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION, ()=>{});
            } else {
                let confirmDialog  = custom({
                    title: "Confirm",
                    messageHtml: `<b>${t('common.confirmDelete')}</b>`,
                    buttons: [{
                        text: `${t('button.yes')}`,
                        icon:"remove",
                        type:"danger",
                        onClick: () => {
                            setShowOverLay(true);
                            RoleService.deletePermissions(currentRole, applicationId)
                            .then(
                                async response => {
                                    if (!response.ok){
                                        throw new Error(response.statusText);
                                    }
                                    let permissions = await response.json();
                                    currentRole.permissions = [];
                                    setShowOverLay(false);
                                    let message = `${t('success.successfullyDeletePermission')}`;
                                    setModalPermissions(permissions);
                                    Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, ()=>{
                                        displayConfigurationUpdateGrid();
                                    });
                                }
                            ).catch(
                                (error) => {
                                    setShowOverLay(false);
                                    console.error("error", error);
                                    let message =  error.message.toLowerCase() === "unauthorized" ? 
                                    `<span>
                                        ${t('error.noaccess')}
                                    </span>`:
                                    `<span>
                                        ${t('error.manageDeletingPermissionMsg')} <br/> 
                                        ${t('error.msg1')}<br/>
                                        ${t('error.msg2')}
                                    </span>`;
                                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION, ()=>{});
                                }
                            )
                        }
                    },{
                        text: `${t('button.cancel')}`
                    }]
                });
                confirmDialog.show();
            }
        }
    }

    // Handles Subscribe Permission input changes
    const handlePermissionInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName: string = e.target.name;
        const fieldValue: string = e.target.value;
        const newField = {[fieldName]: { value: fieldValue }};
        //Using the spread operator we pass all newField props to permissionForm props
        setPermissionForm({...permissionForm, ...newField})
    }


    //Add Permission
    const addPermission = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isValid = validatePermssionForm();
        if (isValid) {
            
            let data = [...modalPermissions];
            let permission  = new Permission(0);
            permission.name = permissionForm.permission.value;
            permission.description = permissionForm.description.value;
            if (data.filter(e => e.name === permission.name).length === 0) {
                setShowOverLay(true);
                data.push(permission);
                currentRole.permissions = data;
                RoleService.addPermission(currentRole, applicationId)
                .then(
                    async response => {
                        if (!response.ok){
                            throw new Error(response.statusText);
                        }

                        let role = await response.json();
                        setModalPermissions(role.permissions);
                        displayConfigurationUpdateGrid();
                        setShowOverLay(false);
                        setPopupVisible(false);
                        let message = `${t('success.successfullyAddPermission')}`
                        Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, ()=>{});
                    }
                ).catch(
                    (error) => {
                        setShowOverLay(false);
                        console.error("error", error);
                        let message =  error.message.toLowerCase() === "unauthorized" ? 
                        `<span>
                            ${t('error.noaccess')}
                        </span>`:
                        `<span>
                            ${t('error.manageAddPermissionMsg')} <br/> 
                            ${t('error.msg1')}<br/>
                            ${t('error.msg2')}
                        </span>`;
                        Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION, ()=>{});
                    }
                )
                
            }
            setPermissionForm({
                permission: {value: '', error: '', isValid: true},
                description: {value: '', error:'', isValid: true},
            });
        }
    }

    //Validate Form
    const validatePermssionForm = () => {
        let newForm: PermissionForm = permissionForm;
        // Validator Permission
        if(!/^PERMISSION_([a-zA-Z ]{1,10})$/.test(permissionForm.permission.value)) {
            const errorMsg: string = t('validation.invalidPermissionName');
            const newField: Field = {value: permissionForm.permission.value, error: errorMsg, isValid: false};
            newForm = {...newForm, ...{permission: newField}}
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        } else {
            const newField: Field = {value: permissionForm.permission.value, error: '', isValid: true};
            newForm = {...newForm, ...{permission: newField}}
        }

        if (Utilities.validNotEmptyValue(permissionForm.description.value)) {
            const newField: Field = {value: permissionForm.description.value, error: '', isValid: true};
            newForm = {...newForm, ...{description: newField}}
        } else {
            const errorMsg: string = t('validation.invalidPermissionDescription');
            const newField: Field = {value: permissionForm.description.value, error: errorMsg, isValid: false};
            newForm = {...newForm, ...{description: newField}}
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }
        setPermissionForm(newForm)
        return newForm.permission.isValid && newForm.description.isValid;
    };

    /**
     * Render popup template
     * @returns 
     */
     const popupTemplate = () => {
        return (

            <LoadingOverlay
                active={showOverLay}
                spinner={<BounceLoader />}>
            <div className="container">
                <div className="row">
                    <form onSubmit={e => addPermission(e)}>
                        <div className="input-field col s12">
                            <input 
                                id="permission"
                                name="permission"
                                type="text"
                                placeholder="Permission"
                                value={permissionForm.permission.value}
                                onChange={e => handlePermissionInputChange(e)}
                                autoComplete="off">
                            </input>
                            <span className="helper-text" >{t('permissionStepper.helperText')}</span>
                        </div>
                        <div className="input-field col s12">
                            
                            <input 
                                id="description"
                                name="description"
                                type="text"
                                placeholder="Description"
                                value={permissionForm.description.value}
                                onChange={e => handlePermissionInputChange(e)}
                                autoComplete="off">
                            </input>
                        </div>
                        <div className="input-field col s12 right-align">
                            <button type="submit" className="waves-effect waves-light btn">
                                <i className="material-icons left">add</i>{t('button.add')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            </LoadingOverlay>
        )
    }

    /** 
     * close Button options
     */
    let closeButtonOptions = () => {
        setPopupVisible(false);
        setPermissionForm({
            permission: {value: '', error: '', isValid: true},
            description: {value: '', error:'', isValid: true},
        });
    }

    /**
     * Update Modal Status
     */
    const updateModalStatus = () => {
        if (!isManagePermission) {
            currentRole.permissions = [];
            setModalPermissions(() => {
                let modalPermissions: Permission[] =[];
                return modalPermissions;
            });
        }
        clearModal();
    }

    useEffect(() => {
        let modalPermissions = [...permissions];
        setModalPermissions(modalPermissions);
        if (isModalOpen) {
            let modalElt = document.getElementById('assignPermissionModal');
            let option = {
                dismissible: false
            };
            let modalInstance = M.Modal.init(modalElt, option);
            modalInstance.open();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isModalOpen])

    return (
        <div>   
            {/** Permission modal */}
            <div id="assignPermissionModal" className="modal modal-fixed-footer">
                <div className="modal-content">
                <LoadingOverlay
                    active={showOverLay}
                    spinner={<BounceLoader />}>
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h4 className="center-align">{t('common.assignPermissionTo')} {currentRole.name}</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                { 
                                    modalPermissions.map(data => (
                                        <div key={data.id} style={{marginBottom: '15px'}}>
                                            
                                            <label>
                                                <input id={`${data.id}`} type="checkbox" 
                                                       value={data.name} className="filled-in"
                                                       defaultChecked={isManagePermission}
                                                       onChange={e => onChange(data, e)} />
                                                <span>{data.name}</span>
                                            </label>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
                </div>

                <div className="modal-footer">
                    <button className={`btn waves-effect waves-light teal lighten-2 ${isManagePermission ? "": " modal-close"} ${!showOverLay ? "": " disabled"}`}
                            onClick={isManagePermission? () => createPermission() : () => updateGrid('ASSIGN')}>
                        {isManagePermission? t('button.add') : t('button.assign')}
                    </button>
                    &nbsp;&nbsp;
                    {
                        isManagePermission &&
                        <button className={`btn waves-effect waves-light red lighten-1 ${!showOverLay ? "": " disabled"}`}
                            onClick={() => deletePermission()}>
                            {t('button.delete')}
                        </button>
                    }
                    &nbsp;&nbsp;
                    <button className={`btn waves-effect waves-light grey lighten-1 modal-close ${!showOverLay ? "": " disabled"}`}
                            onClick={updateModalStatus}>
                        {t('button.cancel')} <i className="material-icons left">close</i>
                    </button>
                </div>
            </div>

            <Popup
                visible={popupVisible}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                onHiding={closeButtonOptions}
                title={t('common.addSetPermission')}
                width={500}
                height={400}
                contentRender={popupTemplate}>             
            </Popup>
        </div>
    )
}

export default AssignPermissionModal;