import React, { FunctionComponent } from 'react';
import SubscribedApplication from '../models/subscribe-application';
import "./application-item.css";
import { Watermark } from '@hirohe/react-watermark';
import { useTranslation } from "react-i18next";

type Props = {
    application : SubscribedApplication
};

const ApplicationItemPending: FunctionComponent<Props> = ({application}) => {
    const { t } = useTranslation();
    return (
        <div className="col m6 s12">
            <Watermark 
                text={t('watermark.pending')}
                textColor="#01579b">
                <div className="card teal lighten-5 z-depth-3">
                    <div className="card-content">
                        <span className="card-title">{application.name}</span>
                        <p>{application.description}</p>
                        <br/>
                        <p style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{application.appId}</p>
                    </div>
                    <div className="card-action">
                        <div>
                            <small>
                                {t('subscription.subscribedOn')} : {application.startDate !== '00/00/0000'?(application.startDate):'---'}
                            </small>
                        </div>
                        <div>
                            <small>
                                {t('subscription.expiredOn')} : {application.endDate !== '00/00/0000'?(application.endDate):'---'}
                            </small>
                        </div>                 
                    </div>
                </div>
            </Watermark>    
        </div>
    )
}
export default  ApplicationItemPending;