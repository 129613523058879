import React, { FunctionComponent, useState } from 'react'
import Notification from '../helpers/notification';
import Role from '../models/role';
//@ts-ignore
import { Toolbar, Data } from "react-data-grid-addons";
//@ts-ignore
import ReactDataGrid from "react-data-grid";
import Utilities from '../helpers/utilities';
import { constants } from '../models/constants';
import { useTranslation } from 'react-i18next';

type Props = {
    backStep: Function,
    nextStep : Function,
    roleNotification: Function
};

type Field = {
    value: any,
    error: string,
    isValid?: boolean
}

type RoleForm = {
    role: Field,
    description: Field,
}

type UpdateRow = {
    fromRow: any;
    toRow: any;
    updated: any
}

const RoleStepperItem: FunctionComponent<Props> = ({backStep, nextStep, roleNotification}) => {
    const selectors = Data.Selectors;
    const [filters, setFilters] = useState({});
    const [roleSource, setRoleSource] = useState<Role[]>([]);
    const [roleForm, setRoleForm] = useState<RoleForm>({
        role: {value: '', error: '', isValid: true},
        description: {value: '', error:'', isValid: true},
    });
    const { t } = useTranslation();

    /**
     * Grid params for displaying permissions added by user
     */
    const gridMinHeight = constants.REACT_DATA_GRID_ADAZZLE_GRID_MIN_HEIGHT;
    const rolecolumns = [
        { key: "id", name: "Identifier", width: 200, sortDescendingFirst: true, editable: false, sortable: true, filterable: false, resizable: true},
        { key: "name", name: "Role", width: 200, sortable: true, editable: true, filterable: true, resizable: true},
        { key: "description", name: "Description", width: 200, sortable: true, editable: true, filterable: true, resizable: true},
        { key: "action", name: "Action", width: 310, resizable: true}
    ]

    const getCellActions = (column: any, row: any) => {
        const cellActions = [
            {
                icon: <div className="left"><button className="waves-effect waves-light btn btn-small red lighten-1">{t('button.delete')}</button></div>,
                callback: () => {
                    let data = [...roleSource];
                    data = data.filter(e=> e.id !== row.id);
                    notifyRoleObservers(data);
                    setRoleSource(data);
                }
            }
        ]
        return column.key === "action" ? cellActions : null;
    };

    /**
     * Get rows
     * @param rows 
     * @param filters 
     */
    const getRows = (rows: Role[], filters: any) => {
        return selectors.getRows({ rows, filters });
    }

    let filteredRows = getRows(roleSource, filters);
    const [rows, setRows] = useState(filteredRows);

    /**
     * Update Permission grid 
     * We will call the backend for updating value.
     * @param param0 
     */
    const onGridRowsUpdated = ( { fromRow, toRow, updated }: UpdateRow ) => {
        let isValidName = true;
        let isValidDescription = true;
        const data = [...filteredRows];
        if (Object.keys(updated)[0] === 'name') {
            if(!/^ROLE_([a-zA-Z ]{1,10})$/.test(updated.name )) {
                const errorMsg: string = t('validation.invalidRoleName');
                isValidName = false
                Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
            }
        } else {
            if (!Utilities.validNotEmptyValue(updated.description)) {
                const errorMsg: string = t('validation.invalidRoleDescription');
                isValidDescription = false;
                Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
            }
        }

        if (isValidName && isValidDescription) {
            setRoleSource(() => {
                const rows = data.slice();
                for (let i = fromRow; i <= toRow; i++) {
                    rows[i] = {...rows[i], ...updated };
                }
                notifyRoleObservers(rows);
                return rows;
            })
        }   
    }

    /**
     * 
     * @param initialRows 
     * @param sortColumn 
     * @param sortDirection 
     */
    const sortRows = (initialRows : any, sortColumn: any, sortDirection: string) => (rows: any) => {

        const compare = (a: any, b: any) => {

            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(compare);
    }

    // Sort Grid
    const gridSort = (sortColumn: any, sortDirection: string) => {
        
        setRows(sortRows(filteredRows, sortColumn, sortDirection));
        return rows
    }

    /**
     * Handle filter Change
     * @param filter 
     */
    const handleFilterChange = (filter: any) => (filters: any) => {
        const newFilters = {...filters };
        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }
        return newFilters;
    }

    // Handles Subscribe Permission input changes
    const handleRoleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const fieldName: string = e.target.name;
        const fieldValue: string = e.target.value;
        const newField = {[fieldName]: { value: fieldValue }};
  
        //Using the spread operator we pass all newField props to permissionForm props
        setRoleForm({...roleForm, ...newField})
    }

    //Validate Form
    const validateRoleForm = () => {
        let newForm: RoleForm = roleForm;
        // Validator Permission
        if(!/^ROLE_([a-zA-Z ]{1,10})$/.test(roleForm.role.value)) {
            const errorMsg: string = t('validation.invalidRoleName');            
            const newField: Field = {value: roleForm.role.value, error: errorMsg, isValid: false};
            newForm = {...newForm, ...{role: newField}}
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        } else {
            const newField: Field = {value: roleForm.role.value, error: '', isValid: true};
            newForm = {...newForm, ...{role: newField}}
        }

        if (Utilities.validNotEmptyValue(roleForm.description.value)) {
            const newField: Field = {value: roleForm.description.value, error: '', isValid: true};
            newForm = {...newForm, ...{description: newField}}
        } else {
            const errorMsg: string = t('validation.invalidRoleDescription'); ;
            const newField: Field = {value: roleForm.description.value, error: errorMsg, isValid: false};
            newForm = {...newForm, ...{description: newField}}
            Notification.showAlert(errorMsg, constants.TIME_ERROR_NOTIFICATION);
        }
        setRoleForm(newForm)
        return newForm.role.isValid && newForm.description.isValid;
    };

    //Add Role
    const addRole = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isValid = validateRoleForm();
        if (isValid) {
            const data = [...roleSource];
            setRoleSource(() => {
                let role  = new Role(0);
                role.name = roleForm.role.value;
                role.description = roleForm.description.value;
                role.id = data.length === 0 ? data.length + 1 : data[data.length - 1].id + 1
                if (data.filter(e => e.name === role.name).length === 0) {
                    data.push(role);
                }
                filteredRows = getRows(data, filters)
                return filteredRows;
            });
            notifyRoleObservers(data);
            setRoleForm({
                role: {value: '', error: '', isValid: true},
                description: {value: '', error:'', isValid: true},
            });
        }
    }

    /**
     * Notify Roles Observers
     * @param roles 
     */
    const notifyRoleObservers = (roles: Role[])=> {
        roleNotification(roles);
    }

    /**
     * Go back to previous step
     */
    const moveToPreviousStep = () => {
        backStep(1);
    }

    /**
     * Move to next step
     */
    const moveToNextStep = () => {
        nextStep(3);
    }

    return (
        <div>
            <div className="step-title">{t('roleStepper.stepper_1_title')}</div>
            <div className="container step-content">
                <div className="row">
                    <form onSubmit={e => addRole(e)}>
                        <div className="input-field col s12 l4 m4">
                            <input 
                            id="role"
                            name="role"
                            type="text"
                            placeholder="Role"
                            value={roleForm.role.value}
                            onChange={e => handleRoleInputChange(e)}
                            autoComplete="off">
                            </input>
                            <span className="helper-text" >{t('roleStepper.helperText')}</span>
                        </div>

                        <div className="input-field col s12 l4 m4">
                            <input 
                            id="description-role"
                            name="description"
                            type="text"
                            placeholder="Description"
                            value={roleForm.description.value}
                            onChange={e => handleRoleInputChange(e)}
                            autoComplete="off">
                            </input>
                        </div>

                        <div className="input-field col s12 l4 m4">
                            <button type="submit" className="waves-effect waves-light btn">
                                <i className="material-icons left">add</i>{t('button.add')}
                            </button>
                        </div>
                    </form>
                </div>
                
                <div className="row">
                    <div className="col s12 l9 m9">
                    <ReactDataGrid
                        columns={rolecolumns}
                        rowGetter={(i: number) => filteredRows[i]}
                        rowsCount={filteredRows.length}
                        enableCellSelect={true}
                        onGridRowsUpdated={onGridRowsUpdated}
                        minHeight={gridMinHeight}
                        onGridSort={gridSort}
                        toolbar={<Toolbar enableFilter={true} />}
                        onAddFilter={(filter: any) => setFilters(handleFilterChange(filter))}
                        onClearFilters={() => setFilters({})}
                        getCellActions={getCellActions}
                         />
                    </div>
                </div>

                <div className="row">
                    <div className="col s12 step-action">
                        
                        <button className="waves-effect waves-light btn" onClick={moveToPreviousStep}><i className="material-icons left">navigate_before</i>{t('navigation.stepperBack')}</button>
                        <span>&nbsp;&nbsp;</span>
                        {
                            roleSource && roleSource.length > 0 &&
                            <button className="waves-effect waves-light btn" onClick={moveToNextStep}><i className="material-icons left">navigate_next</i>{t('navigation.stepperNext')}</button>
                        }   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RoleStepperItem;