import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import SubscribedApplication from '../models/subscribe-application';
import { Watermark } from '@hirohe/react-watermark';
import { useTranslation } from "react-i18next";

type Props = {
    application : SubscribedApplication
};

const ApplicationItem: FunctionComponent<Props> = ({application}) => {

    const history = useHistory();
    const tommorow = new Date();
    tommorow.setDate(tommorow.getDate() + 1);
    const { t } = useTranslation();

    // Set Role and Permission
    const setRoleAndPermission = (id: number) => {
        history.push(`${id}/configurations`);
    }

    //Manage user for an applications
    const superviserUser = (id: number) => {
        history.push(`${id}/manage/users`);
    }

    // Display configure Roles and permissions
    const getConfigureRoleAndPermissions = (id: number) => {
        history.push(`${id}/display/configurations`);
    }

    return (
        <div className="col m6 s12">
            <Watermark 
                text={t('watermark.approved')}
                textColor="#1b5e20">
                <div className="card teal lighten-5 z-depth-3">
                    <div className="card-content">
                        <span className="card-title">{application.name}</span>
                        <p>{application.description}</p>
                        <br/>
                        <p style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>{application.appId}</p>
                    </div>
                    <div className="card-action">
                        <div>
                            <small>
                                {t('subscription.subscribedOn')} : {application.startDate !== '00/00/0000'?(application.startDate):'---'}
                            </small>
                        </div>
                        <div>
                            <small>
                                {t('subscription.expiredOn')} : {application.endDate !== '00/00/0000'?(application.endDate):'---'}
                            </small>
                        </div>
                        <div>&nbsp;</div>
                        <span className="btn btn-small btn-floating waves-effect waves-light">
                            <i onClick={() => setRoleAndPermission(application.id)} className="material-icons">post_add</i>
                        </span>
                        <span>&nbsp; &nbsp;</span>
                        <span className="btn btn-small btn-floating waves-effect waves-light">
                            <i onClick={() => superviserUser(application.id)} className="material-icons">supervisor_account</i>
                        </span>
                        <span>&nbsp; &nbsp;</span>
                        <span className="btn btn-small btn-floating waves-effect waves-light">
                            <i onClick={() => getConfigureRoleAndPermissions(application.id)} className="material-icons">assignment</i>
                        </span>                     
                    </div>
                </div>
            </Watermark>
            
        </div>
    )
}

export default  ApplicationItem;