import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import AssignPermissionToRole from "../models/assignPermToRole";
import Permission from "../models/permission";
import Role from "../models/role";
import Notification from "../helpers/notification";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/base.css'
import '@inovua/reactdatagrid-community/theme/default-light.css'
import AssignPermissionModal from "./assign-permission-modal";
import DisplayAssignPermissionRoleStepperItem from "./display-assign-permission-role-stepper-item";
import { useHistory } from "react-router-dom";
import ConfigurationService from "../services/configuration-service";
import "./assign-permission-role-stepper-item.css";
import { constants } from "../models/constants";
import { useTranslation } from 'react-i18next';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'

type Props = {
    applicationId: string,
    applicationOwnerId: string,
    backStep: Function,
    permissions: Permission[],
    roles: Role[];
};

const AssignPermissionRoleStepperItem: FunctionComponent<Props> = ({applicationId, applicationOwnerId, backStep, permissions, roles}) => {
    const history = useHistory();
    const [currentRole, setRole] = useState<Role>(new Role(-1));
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    const [assignPermToRoleSource, setAssignPermToRoleSource] = useState<AssignPermissionToRole[]>([]);
    const [displayAssignPermToRoleSource, setDisplayAssignPermToRoleSource] = useState<AssignPermissionToRole[]>([]);
    const [saveSettings, setSaveSettings] = useState<Role[]>([]);
    const columns = [
        { name: 'id',header: 'Identifiant', sortable:false, type: 'number'},
        { name: 'role', header: 'Role', type:'string'},
        { name: 'permissions', header: 'Permission(s)', type:'string'},
        { name: 'action', header: 'Action',
        render: ({data}: any) => {

            const assign = () => {
                currentRole.id = data.id;
                currentRole.name = data.role;            
                setRole(currentRole);
                setDisplayModal(true);
            };
            return  <div style={{ display: 'inline-block' }}>
                        <button className="waves-effect waves-light btn btn-small" onClick={assign}>
                            {t('button.edit')}<i className="material-icons left">edit</i>
                        </button>
                    </div>
        }}
    ]

 
    /**
     * Grid params for displaying permissions added by user
     */
    const gridStyle = { minHeight: constants.REACT_DATA_GRID_INOVUA_GRID_MIN_HEIGHT };
    const { t } = useTranslation();
  
    /**
     * Close the modal
     */
    const closeModal = () => {
        setDisplayModal(false);
    }
    
    /**
     * Update Display Grid
     * @param permissions 
     */
    const updateGrid = (role: Role, action: string) => {
        let tempRoles = [...saveSettings];
        if (action === 'ASSIGN') {
            let data: AssignPermissionToRole[] = [];
            currentRole.permissions.forEach(permission => {
                let assignPermToRole = new AssignPermissionToRole(0);
                assignPermToRole.id = currentRole.id;
                assignPermToRole.role = currentRole.name;
                assignPermToRole.permission = permission.name;
                data.push(assignPermToRole);
            });
            let existingData = [...displayAssignPermToRoleSource];
            data.forEach(assingPermissionToRole => {
                let predicat = existingData.some(e => e.role === assingPermissionToRole.role && 
                                                      e.permission === assingPermissionToRole.permission);
                if (!predicat) {
                    existingData.push(assingPermissionToRole);
                    setDisplayAssignPermToRoleSource(existingData);
                }
            });
        } else if (action === 'DELETE') {
            let dataSource: AssignPermissionToRole[] = [...displayAssignPermToRoleSource];
            currentRole.permissions.forEach(permission => {
                setDisplayAssignPermToRoleSource(() => {
                    let index = dataSource.findIndex(e => e.role === currentRole.name && 
                                                     e.permission === permission.name);
                    if (index > -1) {
                        dataSource.splice(index, 1);
                    }
                    return dataSource;
                });
            });         
        }
        if (tempRoles.length > 0) {
            const predicat = tempRoles.some(e => e.name === currentRole.name);
            if (predicat) {
                let index = tempRoles.findIndex(e => e.name === currentRole.name);
                tempRoles[index].name = currentRole.name;
                currentRole.permissions.forEach(permission =>{
                    let idx = tempRoles[index].permissions.findIndex(e => e.name === permission.name);                    
                    if (action === 'ASSIGN') {
                        if (idx > -1) {
                            tempRoles[index].permissions.splice(idx, 1);
                        }
                        tempRoles[index].permissions.push(permission);
                    } else if (action === 'DELETE') {
                        if (idx > -1) {
                            tempRoles[index].permissions.splice(idx, 1);
                        }
                    }
                })
            } 
        }
        setSaveSettings(tempRoles);
    }

    /**
     * Go back to previous step
     */
    const moveToPreviousStep = () => {
        let data = [...displayAssignPermToRoleSource];
        
        setDisplayAssignPermToRoleSource(() => {
            data.splice(0, data.length);
            return data;
        })
        backStep(2);
    }

    /**
     * 
     */
    const save = () => {
        setShowOverlay(true);
        console.log('data', saveSettings);
        ConfigurationService.saveAssignPermissionToRole(+applicationId, saveSettings).then(
            async response  => {
                await response.json();
                if (!response.ok){
                    throw new Error(response.statusText);
                }
                setShowOverlay(false);
                let message = `<span>${t('success.roleAndPermissionAssignedSuccessfully')}</span>`;
                Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {});
                history.replace(`/owners/${applicationOwnerId}/applications/`);
            }
        ).catch(
            (error) => {
                console.error("error", error);
                let message =  `<span>
                                    ${t('error.msg')} <br/> 
                                    ${t('error.msg1')}<br/>
                                    ${t('error.msg2')}
                                </span>`;
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                setShowOverlay(false);
            }
        )
    }

    /**
     * Init Data Grid
     */
    const initDataGrid = () => {
        let dataSource: Array<AssignPermissionToRole> = [];
        roles.forEach(role => {
            let data: any = {};
            data["id"] = role.id;
            data["role"] = role.name;
            data["permissions"] = "N/A";
            dataSource.push(data);
        })
        setAssignPermToRoleSource(dataSource);
    }

    /**
     * Load and update Grid
     */
    useEffect(() => {
        initDataGrid();
        setSaveSettings(roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[roles])

    /**
     * Load and update permissions list 
     */
    useEffect(()=> {
    },[permissions])
    return (
        <div>
            
            <div className="step-title">{t('assignPermissionToRoleStepper.title')}</div>
            <LoadingOverlay
                active={showOverlay}
                spinner={<BounceLoader />}>
            <div className={`step-content`}>
                <div className="row">
                    <div className="col s12 l6 m6">
                        <ReactDataGrid
                            idProperty="id"
                            style={gridStyle}        
                            columns={columns}
                            emptyText="No data..."
                            dataSource={assignPermToRoleSource} 
                        />
                    </div>

                    {/**Grid for displaying permission being assigned */}
                    <div className="col s12 l6 m6">
                        <DisplayAssignPermissionRoleStepperItem 
                            displayAssignPermissionRoles={displayAssignPermToRoleSource}/>
                    </div>
                    
                </div>

                <div className="row">
                    <div className="col s12 step-action">
                        {/** Button next will be visible only if at least one role has been added. */}
                        <button className={`waves-effect waves-light btn`} onClick={moveToPreviousStep}><i className="material-icons left">navigate_before</i>Back</button>
                        <span>&nbsp;&nbsp;</span>
                        {displayAssignPermToRoleSource && displayAssignPermToRoleSource.length > 0 &&
                        <button className={`waves-effect waves-light btn`} onClick={save}><i className="material-icons left">save</i>Save</button>}   
                    </div>
                </div>
            </div>
            </LoadingOverlay>

            <AssignPermissionModal
                isModalOpen={displayModal}
                isManagePermission={false}
                applicationId={+applicationId}
                clearModal = {closeModal}
                updatedGrid = {updateGrid}
                currentRole={currentRole} 
                permissions = {permissions}/>
        </div>
    )
}

export default AssignPermissionRoleStepperItem;