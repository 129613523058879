import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import SubscribedApplication from "../models/subscribe-application";
import ApplicationService from "../services/application-service";
import Notification from "../helpers/notification";
import DataGrid, { Column, Pager, Paging, Format, FilterRow, Button } from 'devextreme-react/data-grid';
import User from "../models/users";
import UserService from "../services/user-service";
import Loader from "../components/loader";
import Utilities from "../helpers/utilities";
import AddUserDetailsItemModal from "../components/add-user-details-item-modal";
import Role from "../models/role";
import GrantUserRoleItemModal from "../components/grant-user-role-item-modal";
import Permission from "../models/permission";
import DisplayUserRoleModalItem from "../components/display-user-role-item-modal";
import { constants } from "../models/constants";
import "./manage-user.css";
import { useTranslation } from "react-i18next";

type Params = {ownerId: string, applicationId: string};

type ModalRole = {
    id: number, 
    name: string,
    description: string,
    permissions: Permission[],
    createdDate: Date,
    lastModifiedDate: Date,
    isChecked: boolean}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ManageUser : FunctionComponent<RouteComponentProps<Params>> = ({match}) => {
    
    const { t } = useTranslation();
    const history = useHistory();
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [addUserModal, setAddUserModal] = useState<boolean>(false);
    const [grantUserRoleModal, setGrantUserRoleModal] = useState<boolean>(false);
    const [displayRoleModal, setDisplayRoleModal] = useState<boolean>(false);
    const [application, setApplication] = useState<SubscribedApplication|null>(null);
    const [errorState, setErrorState] = useState<String>("");
    const [users, setUsers] = useState<User[]>([]);
    const [modalRoles, setModalRole] = useState<ModalRole[]>([]);
    const [user, setUser] = useState<User>(new User(-1));
    const [roles, setRoles] = useState<Role[]>([])

    /**
     * Returns to all applications
     */
    const goBackToApplications = () => {
        history.replace(`/owners/${match.params.ownerId}/applications/`);
    }

    /**
     * Format Date
     * @param value 
     * @returns 
     */
    const formatDate = (value: Date) => {
        return Utilities.dateToString(value);
    }

    /**
     * Add user to the application 
     */
    const add = () => {
        setAddUserModal(true);
    }

    /**
     * Close the modal
     */
    const closeModal = () => {
        setAddUserModal(false);
        setGrantUserRoleModal(false);
        setDisplayRoleModal(false);
    }

    /**
     * Synchronize Manage Users Grid
     */
    const updatedGrid = (user: User) => {
        const data = [...users];
        setUsers(() => {
            data.push(user);
            return data;
        });
    }

    /**
     * Reload the page when a role has been granted or revoked
     */
    const reloadPage = () => {
        window.location.reload();
    }

    /**
     * Assign roles
     * @param value 
     */
    const assignRole = (value: User) => {
        setGrantUserRoleModal(true);
        let user: User = value
        setUser(user)
    }

    /**
     * Display roles
     * @param value 
     */
    const displayRole = (value: User) => {
        setDisplayRoleModal(true);
        let user: User = value;
        setUser(user)
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    const commandAction = (data: User) => {
        var elt = 
        <div>
            <button 
                className={`waves-effect waves-light btn btn-small ${roles.length > 0 ? "": " disabled"}`}
                onClick={() => assignRole(data)}>{t('button.assignRole')}
            </button>
            &nbsp;&nbsp;
            {
                data.roles && data.roles.length > 0 && 
                <button 
                    className={`waves-effect waves-light btn btn-small`} 
                    onClick={() => displayRole(data)}>{t('button.displayRole')}
                </button>
            }
            
        </div>
        return elt
    } 
    useEffect(() => {
        setShowLoader(true);
        //Load the application
        ApplicationService.getApplication(+match.params.applicationId)
        .then(
            async response => {
                const data: SubscribedApplication =  await response.json();
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                setApplication(data);
                //Load the users of the application
                UserService.fetch(+match.params.applicationId)
                .then(
                    async response => {
                    let users: User[] =  await response.json();
                    if (!response.ok){
                        throw new Error(response.statusText);
                    }
                    setUsers(users);

                    //Load Roles of the application
                    UserService.fetchRoles(+match.params.applicationId)
                    .then(
                        async response => {
                        let roles: Role[] = await response.json();
                        setRoles(roles);
                        let modalRoles: ModalRole[] = [];
                        

                        roles.forEach((role: Role) =>{
                            let modalRole: ModalRole = {
                                id: 0, 
                                name: "",
                                description: "",
                                permissions: [],
                                createdDate: new Date(),
                                lastModifiedDate: new Date(),
                                isChecked: false
                            }
                            modalRole.id = role.id;
                            modalRole.name = role.name;
                            modalRole.description = role.description;
                            modalRole.permissions = role.permissions;
                            modalRole.createdDate = role.createdDate;
                            modalRole.lastModifiedDate = role.lastModifiedDate
                            modalRole.isChecked = false;
                            modalRoles.push(modalRole);
                        })
                        setModalRole(modalRoles);
                        if (!response.ok){
                            throw new Error(response.statusText);
                        }
                        setShowLoader(false);
                    }).catch((error) => {
                        console.error("error", error);
                        let message =  error.message.toLowerCase() === "unauthorized" ? 
                            `<span>${t('error.noaccess')}</span>`:
                            `<span>
                                ${t('error.msg')} <br/> 
                                ${t('error.msg1')}<br/>
                                ${t('error.msg2')}
                            </span>`;
                        setShowLoader(false);
                        Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                    });
                }).catch((error) => {
                    console.error("error", error);
                    let message =  error.message.toLowerCase() === "unauthorized" ? 
                    `<span>${t('error.noaccess')}</span>`:
                    `<span>
                        ${t('error.msg')} <br/> 
                        ${t('error.msg1')}<br/>
                        ${t('error.msg2')}
                    </span>`
                    setErrorState(message);
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                });
            }).catch((error) => {
                console.error("error", error);
                    
                let message =  error.message.toLowerCase() === "unauthorized" ? 
                    `<span>${t('error.noaccess')}</span>`:
                    `<span>
                        ${t('error.msg')} <br/> 
                        ${t('error.msg1')}<br/>
                        ${t('error.msg2')}
                    </span>`
                setErrorState(message);
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
            });

                
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.applicationId]);
    return(
        <div className="manage-user-css">
            {
                /** breadcumb section */
                <div className="container">
                    <div className="row"></div>
                    <div className="row">
                        <div className="col s12 m12">
                            <nav className="nav-wrapper teal">                
                                <a href="#!" className="breadcrumb" onClick={goBackToApplications} style={{marginLeft: '5px'}}>Applications</a>
                                {application && <a href="#!" className="breadcrumb" style={{marginLeft: '5px'}}>{application.name}</a>}
                                <a href="#!" className="breadcrumb" >{t('common.manageUser')}</a>
                            </nav>
                        </div> 
                    </div>
                </div>
                /** breadcumb section */
            }
            {
                showLoader &&
                <div className='container'>
                    <div className="row">
                        <div className="col m12 s12">
                            <Loader/>
                        </div>
                    </div>
                </div>
            }
            { 
                errorState === "" && !showLoader &&
                <div className="container">
                    <div className="row">
                        <div className="col s12">
                            <Link to={`/owners/${+match.params.ownerId}/applications/${+match.params.applicationId}/configurations`} >
                                {t('button.addNewRole')}
                            </Link>
                        </div>
                        <div className="col s12">
                            <Link to={`/owners/${+match.params.ownerId}/applications/${+match.params.applicationId}/display/configurations`} >
                                {t('button.displayRoleAndPermission')}
                            </Link>
                        </div>
                        <div className="col s12"><br/></div>
                    </div>
                    <div className="row">
                        <div className="col m4 l3 s12">
                            <button className="waves-effect waves-light btn" onClick={add} >
                                <i className="material-icons left">add</i>{t('button.add')}
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12">
                            <DataGrid
                                dataSource={users}
                                showBorders={true}
                                columnAutoWidth={true}>
                                <FilterRow visible={true}/>
                                <Paging defaultPageSize={10}/>
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[5, 10, 15, 20]} 
                                    showInfo={true}/>
                                    
                                <Column dataField="id" width={130}  allowFiltering = {false} caption={t('common.personalNumber')}/>
                                <Column dataField="email"/>
                                <Column 
                                    dataField="creationDate" 
                                    dataType="date" 
                                    caption={t('common.insertDate')}>
                                    <Format formatter={formatDate}/>
                                </Column>
                                {
                                    roles.length > 0 &&
                                    <Column type="buttons" width={350} allowFiltering = {false}>
                                        <Button render={(value) => commandAction(value.data)} />
                                    </Column>
                                }
                            </DataGrid>
                        </div>
                    </div>
                </div>
            }
            <AddUserDetailsItemModal
                updateManageUserGrid = {updatedGrid}
                applicationId={+match.params.applicationId}
                isModalOpen={addUserModal}
                clearModal = {closeModal}
            />
                
            <GrantUserRoleItemModal
                isModalOpen={grantUserRoleModal}
                clearModal = {closeModal}
                reloadUserGrid ={reloadPage}
                applicationId={+match.params.applicationId}
                user={user}
                roles = {modalRoles}
            />

            <DisplayUserRoleModalItem
                isModalOpen={displayRoleModal}
                clearModal = {closeModal}
                reloadUserGrid={reloadPage}
                applicationId={+match.params.applicationId}
                user={user}
            />
        </div>
    )
}

export default  ManageUser;