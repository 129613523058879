import Subscribe from "../models/subscribe";
export default class SubscribeService {

    //subscibe users
    static subscribe(subscribe: Subscribe): Promise<Response> {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-type':'application/json'
            },
            body: JSON.stringify(subscribe)
        };
        return fetch(`/ecauthenticateapi/subscribe/users`, requestOptions)
                
    }

    // get Subscribed users
    static getSubscribeUsers() : Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/admin/list/subscribeusers`, requestOptions)
    }

    // get subscribed applications for a specific user
    static getSubscribeApplication(id: number): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/admin/manage/subscribiption/${id}`, requestOptions);   
    }
    // get Expired applications
    static getExpiredSubscribeApplications(id: number): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/admin/manage/subscriptions/${id}/expired`, requestOptions); 
    }
}