//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
enum NotificationType {
    SUCCESS = 'green darken-1 rounded',
    DANGER  = 'red darken-1 rounded',
    WARNING = 'yellow darken-4 rounded'
}

export default class Notification {

    /**
     * Show a success notification
     * @param message 
     * @param delay 
     * @param callback 
     */
    static showSuccess(message: string, delay: number, callback?: Function) {

        return M.toast({
            html: message, 
            displayLength: delay, 
            classes: NotificationType.SUCCESS,
            completeCallback: callback
        });
    }

    /**
     * Show an alert notification
     * @param message 
     * @param delay 
     * @param callback 
     */
    static showAlert(message: string, delay: number, callback?: Function) {

        return M.toast({
            html: message, 
            displayLength: delay, 
            classes: NotificationType.DANGER, 
            completeCallback: callback
        });
    }

    static showWarning(message: string, delay: number, callback?: Function) {
        return M.toast({
            html: message, 
            displayLength: delay, 
            classes: NotificationType.WARNING, 
            completeCallback: callback
        });
    }
}