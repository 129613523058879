
/**
 * Broker gateway
 */
 import SockJS from 'sockjs-client';
 import Stomp from 'stompjs';
export default class BrokerService {

    private static stompClient: Stomp.Client;
    /**
     * Initialize broker
     * @returns 
     */
    static initialize() {
        let sock = new SockJS('http://localhost:8083/ecauthenticateapi/ws');
        let client = Stomp.over(sock);
        sock.onopen = () => {
            console.log('Broker open');
        }
        this.stompClient = client;
    }

    /**
     * Notify admin dashboard after refreshing browser
     */
    static fetchNotifications(): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': authToken
            }
        };
        return fetch(`/ecauthenticateapi/admin/subscriptions/refresh`, requestOptions)
    }

    /**
     * Get Broker
     * @returns 
     */
     static getBrokerClient() {
        return this.stompClient;
    }
}