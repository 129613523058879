import Utilities from "../helpers/utilities";
import Role from "../models/role";
import UserContext from "../models/userContext";

export default class AuthService {

    private static authAsUser: Boolean = false;
    private static notif: number = 0;
    private static context : UserContext = {id: -1, user: "", profile: "USER", roles: []}
    private static authenticateAsAdmin: Boolean = false;

    static auth(): Boolean {
        this.authAsUser = Utilities.validNotEmptyValue(sessionStorage.getItem('logged-in')) && 
                          Utilities.validNotEmptyValue(sessionStorage.getItem('token'))
        return this.authAsUser;
    }

    static authAsAdmin(): Boolean {
        this.authenticateAsAdmin = Utilities.validNotEmptyValue(sessionStorage.getItem('logged-in-admin')) && 
                                   Utilities.validNotEmptyValue(sessionStorage.getItem('token'))
        return this.authenticateAsAdmin;
    }

    static notification(): string | null {
        return sessionStorage.getItem('notification')
    }

    /**
     * Create user context
     * @param user 
     * @param roles 
     */
    static createContext(id: number, user: string, profile: string, roles: Role[]): void {
        this.context.id = id;
        this.context.user = user;
        this.context.profile = profile;
        this.context.roles = roles;
    }

    /**
     * Get Logged User
     */
    static getLoggedUser(): string | null {
        return sessionStorage.getItem('logged-in');
    }

    /**
     * Get token
     */
    static getToken(): string | null {
        return sessionStorage.getItem('token');
    }

    /**
     * Get User Context
     */
    static getUserContext(): UserContext {
        console.log('context', this.context);
        return this.context
    }

    /**
     * Authenticate as an Admin
     */
    static isAdmin() {
        return this.authenticateAsAdmin;
    }

    /**
     * Authenticate as a User
     */
    static isAuth() {
        return this.authAsUser;
    }

}