import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthService from './services/auth-service';
  
const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <Route {...rest} render={(props) => {
    const isAuthenticated = AuthService.isAuth()
    if (!isAuthenticated) {    
      return <Redirect to={{ pathname: '/' }} />
    } 
  
    return <Component {...props} />
  }} />
);
  
export default PrivateRoute;