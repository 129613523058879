import React, { FunctionComponent } from 'react'

import User from '../models/users';
import Loader from './loader';
//@ts-ignore
//import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
import './role.css';


type Props = {
    user: User
}

const RoleItem: FunctionComponent<Props> = ({user}) => {

    return (
        <div>
            {
                user.roles && user.roles.length !==0 ?(user.roles.map((role) => (
                <span key ={role.id} className="chip">{role.name}</span>
                ))) : (<h4 className="center"><Loader/></h4>)
            }            
        </div>
    )
}

export default RoleItem;