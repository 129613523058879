import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import UserService from "../services/user-service";
import Notification from "../helpers/notification";
//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
import Role from "../models/role";
import User from "../models/users";
import Permission from "../models/permission";
import { constants } from "../models/constants";
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { useTranslation } from 'react-i18next';
type Props = {
    isModalOpen: boolean,
    clearModal: Function,
    reloadUserGrid: Function,
    applicationId: number, 
    user: User, 
    roles: ModalRole[]
};

type ModalRole = {
    id: number, 
    name: string,
    description: string,
    permissions: Permission[],
    createdDate: Date,
    lastModifiedDate: Date,
    isChecked: boolean
}

const GrantUserRoleItemModal: FunctionComponent<Props> = ({
    isModalOpen, clearModal, 
    reloadUserGrid, applicationId,
    user, roles}) => {

    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const [rolesToAssign, setRolesToAssign] = useState<Role[]>([]);
    const { t } = useTranslation();

    /**
     * Update Manage User Grid
     */
    const notifyManageUserGrid = () => {
        reloadUserGrid();
    }

    /**
     * Grant Role
     */
    const grant = () => {
        setShowOverlay(true);
        UserService.grantRoles(user.id, applicationId, rolesToAssign)
        .then(
            async response => {
                
                if (!response.ok){
                    throw new Error(response.statusText);
                }
                setShowOverlay(false);
                let message = `${t('success.successfullyAssignRole')}`;
                setRolesToAssign([]);
                Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, ()=>{
                    notifyManageUserGrid();
                });
                
                clearModal();
            }
        ).catch(
            (error) => {
                console.error("error", error);
                let message =  error.message.toLowerCase() === "unauthorized" ? 
                    `<span>
                        ${t('error.noaccess')}
                    </span>`:
                    `<span>
                        ${t('error.grantUserMsg')} <br/> 
                        ${t('error.msg1')}<br/>
                        ${t('error.msg2')}
                    </span>`;
                setShowOverlay(false);
                setRolesToAssign([]);
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION, ()=>{
                    notifyManageUserGrid();
                });
                clearModal();
            }
        );
        roles.forEach(role => {
            role.isChecked = false;
        })
    }

    /**
     * Select Role
     */
     const onChange = (modalRole: ModalRole, e: React.ChangeEvent<HTMLInputElement>) => {
        let checked = e.target.checked;
        let roles: Role[] = [...rolesToAssign];
        let role: Role = {
            id: modalRole.id, 
            name: modalRole.name,
            description: modalRole.description,
            permissions: modalRole.permissions,
            createdDate: modalRole.createdDate,
            lastModifiedDate: modalRole.lastModifiedDate
        }
        if (checked) {
            modalRole.isChecked = true;
            roles.push(role);
        } else {
            modalRole.isChecked = false;
            roles = roles.filter((currentRole) => currentRole.name !== role.name);
        }
        setRolesToAssign(roles);
    }

    /**
     * Closing modal
     */
    const close = () => {
        roles.forEach(role => {
            role.isChecked = false;
        })
        setRolesToAssign([]);
        clearModal();
    }

    useEffect(() => {
        
        if (isModalOpen) {
            let modalElt = document.getElementById('grantUserRoleModalItem');
            let option = {
                dismissible: false
            };
            let modalInstance = M.Modal.init(modalElt, option);
            modalInstance.open();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isModalOpen])
    return (
        <div>
            <div id="grantUserRoleModalItem" className="modal modal-fixed-footer">
                <div className="modal-content">
                    <LoadingOverlay
                        active={showOverlay}
                        spinner={<BounceLoader />}>
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                <h4 className="center-align">{t('common.assignRoleTo')} {user.email}</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12">
                                {
                                    roles.map(data => (
                                        <div key={data.id} style={{marginBottom: '15px'}}>
                                            
                                            <label>
                                                <input id={`${data.id}`} type="checkbox" 
                                                       value={data.name} className="checkbox filled-in"
                                                       checked = {data.isChecked}
                                                       onChange={e => onChange(data, e)} />
                                                <span>{data.name}</span>
                                            </label>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    </LoadingOverlay>
                </div>

                <div className="modal-footer">
                    <button className={`btn waves-effect waves-light teal lighten-2 ${!showOverlay ? "" : " modal-close"} ${!showOverlay && rolesToAssign && rolesToAssign.length > 0 ? "": " disabled"}`}
                            onClick={grant}>
                        {showOverlay && (
                            <i className="material-icons left">refresh</i>
                        )}
                        {showOverlay && <span>{t('button.processing')}</span>}
                        {!showOverlay && <span>{t('button.assign')}</span>}
                    </button>
                    &nbsp;&nbsp;
                    <button className={`btn waves-effect waves-light grey lighten-1 modal-close ${!showOverlay ? "": " disabled"}`}
                            onClick={close}>
                        {t('button.cancel')} <i className="material-icons left">close</i>
                    </button>
                </div>
            </div>
            
        </div>
    )
}

export default GrantUserRoleItemModal