
import React, { FunctionComponent, useState, useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
 import UserItem from '../components/users-item';
import ApplicationService from '../services/application-service';
import './application-owner-detail.css';
import Notification from "../helpers/notification";
//@ts-ignore
import M from '../../node_modules/materialize-css/dist/js/materialize.min.js';
import Loader from '../components/loader';
import SubscribedApplication from '../models/subscribe-application';
import { constants } from '../models/constants';

type Params = {ownerId: string, applicationId: string};

const ApplicationOwnerDetail: FunctionComponent<RouteComponentProps<Params>> = ({match}) => {

    const [application, setApplication] = useState<SubscribedApplication|null>(null);
    const history = useHistory();

    useEffect(() => {

        ApplicationService.getApplication(+match.params.applicationId)
        .then(
            async response => {
                const data: SubscribedApplication =  await response.json();
                if (!response.ok){
                  throw new Error(response.statusText);
                }
                setApplication(data)
            }
        ).catch(
            (error) => {
                console.error("error", error);
                let message =  `<span>
                                    Something went wrong during processing the request. <br/> 
                                    Please try again.<br/>
                                    If issue is persisted. Please send us an email. Thanks
                                </span>`;
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
            }
        );
    }, [match.params.applicationId]);

    //Initialize our Autocomplete component when componentDidMount
    useEffect(() => {

        ApplicationService.getApplicationsByOwner(+match.params.ownerId)
        .then(
            async response => {
                let data: any = {};
                let mapdataToId: any = {};
                const applications: SubscribedApplication[] =  await response.json();
                if (!response.ok){
                  throw new Error(response.statusText);
                }
                applications.map((application) => {
                    data[application.name] = null;
                    mapdataToId[application.name] = application;
                    return mapdataToId;
                })
                let limit = 5; 
                let applicationSearchElt = document.getElementById('autocomplete-application-detail');
                let option = {
                    data: data,
                    limit: limit,
                    minLength: 2,
                    onAutocomplete: (e: string) => {
                        history.replace(`/owners/${match.params.ownerId}/applications/${mapdataToId[e].id}`);
                }
            };

            M.Autocomplete.init(applicationSearchElt, option);
        }).catch(
            (error) => {
                console.error("error", error);
                let message =  `<span>
                                    Something went wrong during processing the request. <br/> 
                                    Please try again.<br/>
                                    If issue is persisted. Please send us an email. Thanks
                                </span>`;
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
            }
        );;

    }, [history, match.params.ownerId]);

    /**
     * Returns to all applications
     */
    const goBackToApplications = () => {
        history.replace(`/owners/${match.params.ownerId}/applications/`);
    }

    return (

        <div>
            {/** breadcumb section */}
            <div className="container">
                <div className="row"></div>
                <div className="row">
                  <div className="col s12 m11 offset-m1">
                      <nav className="nav-wrapper teal">                
                        <a href="#!" className="breadcrumb" onClick={goBackToApplications} style={{marginLeft: '5px'}}>Applications</a>
                        <a href="#!" className="breadcrumb" >Assign role(s) to an user</a>
                      </nav>
                  </div> 
                </div>
            </div>
            {/** breadcumb section */}

            <div className="container">
                    <div className="row">
                        <div className="input-field col s12 m4 offset-m8">
                            <input type="search" id="autocomplete-application-detail" placeholder="Look for an application" autoComplete="off" /> 
                        </div>
                        {
                            application && application.users.length > 0?(
                                <div className="col m11 offset-m1 s12">
                                    <UserItem key={application.id} application={application}/>
                                </div>
                            ):(<h4 className="center"><Loader/></h4>)
                        }      
                    </div>
            </div>
        </div>
    )
}

export default ApplicationOwnerDetail