import React, { FunctionComponent, useEffect, useState } from 'react';
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import Notification from "../helpers/notification";
import '../../node_modules/materialize-stepper/dist/js/mstepper.min.js';
import { constants } from '../models/constants';
import ConfigurationService from '../services/configuration-service';
import { useTranslation } from 'react-i18next';
import SubscribedApplication from '../models/subscribe-application';
import ApplicationService from '../services/application-service';
import Loader from '../components/loader';
import Role from '../models/role';
import DataGrid, { Button, Column, Editing, FilterRow, Format, MasterDetail } from 'devextreme-react/data-grid';
import Utilities from '../helpers/utilities';
import RoleDetailsItem from '../components/role-details-item';
import RoleService from '../services/role-service';
import AssignPermissionModal from '../components/assign-permission-modal';
import Permission from '../models/permission';
import { custom } from 'devextreme/ui/dialog';


type Params = {ownerId: string, applicationId: string};
  
const DisplayConfiguration: FunctionComponent<RouteComponentProps<Params>> = ({match}) => {

    const history = useHistory();
    const [currentRole, setRole] = useState<Role>(new Role(-1));
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [displayModal, setDisplayModal] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [showOverLay, setShowOverLay] = useState<boolean>(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const [application, setApplication] = useState<SubscribedApplication|null>(null);
    const { t } = useTranslation();
  
    /**
     * Returns to all applications
     */
    const goBackToApplications = () => {
      history.replace(`/owners/${match.params.ownerId}/applications/`);
    }

    /**
     * Format Date
     * @param value 
     * @returns 
     */
    const formatDate = (value: Date) => {
        return Utilities.dateToString(value);
    }
    /**
     * Update role
     * @param value 
     */
    const onRoleUpdated = (value: any) => {
        setShowOverLay(true);
        RoleService.updateRole(value.data, +match.params.applicationId)
        .then(
            async response => {
                if (!response.ok){
                    throw new Error(response.statusText);
                }
                setShowOverLay(false);
                let message = ` <span>${t('success.roleUpdate')}</span>`;
                Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, () => {});
            }).catch(
                (error) => {
                    console.error("error", error);
                        let message =  `<span>
                                            ${t('error.msg')}<br/> 
                                            ${t('error.msg1')}<br/>
                                            ${t('error.msg2')}
                                        </span>`;
                    setShowOverLay(false);
                    Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                    window.location.reload();
                }
            )
    }

    /**
     * Manage Permission
     * @param data 
     */
    const managePermission = (data: Role) => {
        let role = data;
        setShowOverLay(true);
        RoleService.getPermission(data.id)
        .then(
            async response => {
                if (!response.ok){
                    throw new Error(response.statusText);
                }
                let permissions = await response.json();
                setRole(role);
                setPermissions(permissions);
                setDisplayModal(true);
                setShowOverLay(false);
            }
        ).catch(
            (error) => {
                console.error("error", error);
                let message =  `<span>
                                    ${t('error.loadingPermissionToManageMsg')}<br/> 
                                    ${t('error.msg1')}<br/>
                                    ${t('error.msg2')}
                                </span>`;
                setShowOverLay(false);
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
        })
    }

    /**
     * Delete Role
     * @param data 
     */
    const deleteRole = (data: Role) => {
        let confirmDialog  = custom({
            title: "Confirm",
            messageHtml: `<b>${t('common.confirmDelete')}</b>`,
            buttons: [{
                text: `${t('button.yes')}`,
                icon:"remove",
                type:"danger",
                onClick: () => {
                    setShowOverLay(true);
                    RoleService.deleteRole(data, +match.params.applicationId)
                    .then(
                        async response => {
                            if (!response.ok){
                                throw new Error(response.statusText);
                            }
                            let roles: Role[] =  await response.json();
                            setShowOverLay(false);
                            setRoles(roles);
                            let message = `${t('success.successfullyDeleteRole')}`;
                            Notification.showSuccess(message, constants.TIME_SUCCESS_NOTIFICATION, ()=>{
                            });
                        }
                    ).catch(
                        (error) => {
                            setShowOverLay(false);
                            console.error("error", error);
                            let message =  error.message.toLowerCase() === "unauthorized" ? 
                            `<span>
                                ${t('error.noaccess')}
                            </span>`:
                            `<span>
                                ${t('error.deletingRoleMsg')} <br/> 
                                ${t('error.msg1')}<br/>
                                ${t('error.msg2')}
                            </span>`;
                            Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION, ()=>{});
                        }
                    )
                }
            },{
                text: `${t('button.cancel')}`
            }]
        });
        confirmDialog.show();
    }

    /**
     * Close the modal
     */
    const closeModal = () => {
        setDisplayModal(false);
    }

    const roleDetails = (data: Role) => {
        return <RoleDetailsItem 
                    key={data.id} 
                    role={data}
                    applicationId={+match.params.applicationId}
                    overlay={(value: boolean) => activateOverlay(value)}/>;
    }

    /**
     * Activate Overlay if user modifies permission's description
     */
    const activateOverlay = (value: boolean) => {
        setShowOverLay(value);
    }

    /**
     * 
     * @param data 
     * @returns 
     */
    const commandAction = (data: Role) => {
        var elt = 
        <div>
            <button 
                className={`waves-effect waves-light btn btn-small`}
                onClick={() => managePermission(data)}>{t('button.managePermission')}
            </button>
            &nbsp;&nbsp;
            <button
                className={`waves-effect waves-light btn btn-small red lighten-1`}
                onClick={() => deleteRole(data)}>{t('button.delete')}
            </button>
        </div>
        return elt
    }

    // reload grid
    const updateGrid = () => {
        ConfigurationService.displayAssignPermissionRoles(+match.params.applicationId)
        .then(
            async response => {
            let roles: Role[] =  await response.json();
            if (!response.ok){
                throw new Error(response.statusText);
            }
            setRoles(roles);
        }).catch(
            (error) => {
            console.error("error", error);
            let message =  `<span>
                                ${t('error.msg')}<br/> 
                                ${t('error.msg1')}<br/>
                                ${t('error.msg2')}
                            </span>`;
            setShowLoader(false);
            Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
        });
    }

    /**
     * Load the roles & permissions
     */
    useEffect(() => {
        setShowLoader(true);
        ApplicationService.getApplication(+match.params.applicationId)
        .then(
            async response => {
                const data: SubscribedApplication =  await response.json();
                if (!response.ok){
                  throw new Error(response.statusText);
                }
                setApplication(data);
                ConfigurationService.displayAssignPermissionRoles(+match.params.applicationId)
                .then(
                    async response => {
                    let roles: Role[] =  await response.json();
                    if (!response.ok){
                        throw new Error(response.statusText);
                    }
                    setShowLoader(false);
                    setRoles(roles);
                }).catch(
                    (error) => {
                        console.error("error", error);
                        let message =  `<span>
                                        ${t('error.msg')}<br/> 
                                        ${t('error.msg1')}<br/>
                                        ${t('error.msg2')}
                                        </span>`;
                        setShowLoader(false);
                        Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
                });
        }).catch(
            (error) => {
                console.error("error", error);
                let message =  `<span>
                                    ${t('error.msg')} <br/> 
                                    ${t('error.msg1')}<br/>
                                    ${t('error.msg2')}
                                </span>`;
                setShowLoader(false);
                Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.params.applicationId]);
    
    return (
    <div>
        {/** breadcumb section */}
        <div className="container">
            <div className="row"></div>
            <div className="row">
                <div className="col s12">
                    <nav className="nav-wrapper teal">                
                        <a href="#!" className="breadcrumb" onClick={goBackToApplications} style={{marginLeft: '5px'}}>Applications</a>
                            {application && <a href="#!" className="breadcrumb" style={{marginLeft: '5px'}}>{application.name}</a>}
                        <a href="#!" className="breadcrumb" >Display Role(s) & Permission(s)</a>
                    </nav>
                </div> 
            </div>
        </div>
        {/** breadcumb section */}

        <div className="container">
        {
            showLoader && 
            <div className="row">
                <div className="col m12 s12">
                    <Loader/>
                </div>
            </div>
        }
        {
            !showLoader && 
            <div className="row">
                <div className="col s12">
                <Link to={`/owners/${+match.params.ownerId}/applications/${+match.params.applicationId}/configurations`} >
                    {t('button.addNewRole')}
                </Link>
                </div>
                <div className="col s12">
                <Link to={`/owners/${+match.params.ownerId}/applications/${+match.params.applicationId}/manage/users`} >
                    {t('button.manageUser')}
                </Link>
                </div>
                <div className="col s12"><br/></div>
                <div className="col s12">
                    <LoadingOverlay
                        active={showOverLay}
                        spinner={<BounceLoader />}>
                    <DataGrid
                        dataSource={roles}
                        showBorders={true}
                        columnAutoWidth={true}
                        onRowUpdated={onRoleUpdated}>
                        <Editing 
                            mode="cell"
                            allowUpdating={true}/>
                        <FilterRow visible={true}/>
                        <Column dataField="name" allowEditing={false} caption={t('common.role')}/>
                        <Column dataField="description" caption={t('common.description')}/>
                        <Column 
                            dataField="createdDate"
                            allowEditing={false}
                            dataType="date"
                            caption={t('common.createdDate')}>
                            <Format formatter={formatDate}/>
                        </Column>
                        <Column 
                            dataField="lastModifiedDate"
                            allowEditing={false}
                            dataType="date" 
                            caption={t('common.lastModifiedDate')}>
                            <Format formatter={formatDate}/>
                        </Column>
                        <Column type="buttons" width={350} allowFiltering = {false}>
                            <Button render={(value) => commandAction(value.data)} />
                        </Column>
                        <MasterDetail
                            enabled={true}
                            render={(value) => roleDetails(value.data)}
                        />    
                    </DataGrid>
                    </LoadingOverlay>
                </div>
            </div>
        }
            
        </div>

        <AssignPermissionModal 
            isModalOpen={displayModal}
            isManagePermission={true}
            applicationId={+match.params.applicationId}
            clearModal = {closeModal}
            updatedGrid = {updateGrid}
            currentRole={currentRole} 
            permissions = {permissions}/>
    </div>
  )}
  
export default DisplayConfiguration;