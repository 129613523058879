
export default class Permission {

    id: number;
    name: string;
    description: string;
    createdDate: Date;
    lastModifiedDate: Date

    constructor(
        id: number,
        name: string = "...",
        description: string = "",
        createdDate: Date = new Date(),
        lastModifiedDate: Date = new Date()
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.lastModifiedDate = lastModifiedDate;
        this.createdDate = createdDate;
    }
}