/**
 * Constants values
 */
export const constants = {
    ADMIN_LOGIN: "admin@gmail.com",
    WS_URL:"http://localhost:8083/ecauthenticateapi/ws",
    TIME_SUCCESS_NOTIFICATION: 5000,
    TIME_ERROR_NOTIFICATION: 6000,
    TIME_WARNING_NOTIFICATION: 5000,
    REACT_DATA_GRID_ADAZZLE_COLUMN_MIN_WIDTH: 310,
    REACT_DATA_GRID_ADAZZLE_GRID_MIN_HEIGHT: 300,
    REACT_DATA_GRID_INOVUA_GRID_MIN_HEIGHT: 350
 };