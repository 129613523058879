import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import ApplicationService from '../services/application-service';
import Notification from "../helpers/notification";
import '../../node_modules/materialize-stepper/dist/js/mstepper.min.js';
import PermissionStepperItem from '../components/permission-stepper-item';
import RoleStepperItem from '../components/role-stepper-item';
import Permission from '../models/permission';
import AssignPermissionRoleStepperItem from '../components/assign-permission-role-stepper-item';
import Role from '../models/role';
import SubscribedApplication from '../models/subscribe-application';
import { constants } from '../models/constants';
import { useTranslation } from "react-i18next";

type Params = {ownerId: string, applicationId: string};
  
const SetRoleAndPermission: FunctionComponent<RouteComponentProps<Params>> = ({match}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [application, setApplication] = useState<SubscribedApplication|null>(null);
    const [permissionsDef, setPermissionsDef] = useState<Permission[]>([]);
    const [rolesDef, setRolesDef] = useState<Role[]>([]);
    const [activeStep, setActiveStep] = useState<number>(1);
  
    /**
     * Returns to all applications
     */
    const goBackToApplications = () => {
      history.replace(`/owners/${match.params.ownerId}/applications/`);
    }

    /**
     * Move forward to Step Role
     * @param value 
     */
    const activeStepper = (value: number, roles: Role[], permissions: Permission[]) => {
      setActiveStep(value);
    }

    /**
     * 
     * @param roles 
     */
    const setRoles = (roles: Array<Role>) => {
      let data = [...rolesDef];
      data = roles;
      setRolesDef(data);
    }

    const setPermission = (permissions: Array<Permission>) => {
      let data = [...permissionsDef];
      data = permissions;
      setPermissionsDef(data);
    }

    /**
     * Load and update list of role add by a user
     */
    useEffect(() => {
    },[rolesDef]);

    /**
     * Load and update list of permission add by a user
     */
    useEffect(() => {
    },[permissionsDef])

    /**
     * Load the application
     */
    useEffect(() => {
      ApplicationService.getApplication(+match.params.applicationId)
        .then(
            async response => {
                const data: SubscribedApplication =  await response.json();
                if (!response.ok){
                  throw new Error(response.statusText);
                }
                setApplication(data)
            }
        ).catch(
          (error) => {
              console.error("error", error);
              let message =  `<span>
                                  Something went wrong during processing the request. <br/> 
                                  Please try again.<br/>
                                  If issue is persisted. Please send us an email. Thanks
                              </span>`;
              Notification.showAlert(message, constants.TIME_ERROR_NOTIFICATION);
          }
        );

    }, [match.params.applicationId]);
    
    return (
    <div>
      {/** breadcumb section */}
      <div className="container">
        <div className="row"></div>
          <div className="row">
            <div className="col s12 l9 m9">
                <nav className="nav-wrapper teal">                
                  <a href="#!" className="breadcrumb" onClick={goBackToApplications} style={{marginLeft: '5px'}}>Applications</a>
                  {application && <a href="#!" className="breadcrumb" style={{marginLeft: '5px'}}>{application.name}</a>}
                  <a href="#!" className="breadcrumb" >Set Role(s) & Permission(s)</a>
                </nav>
            </div> 
          </div>
      </div>
      {/** breadcumb section */}

      <div className="container">
            <div className="row">
              <div className="col s12">
                  <Link to={`/owners/${+match.params.ownerId}/applications/${+match.params.applicationId}/manage/users`} >
                      {t('button.manageUser')}
                  </Link>
              </div>
              <div className="col s12">
                  <Link to={`/owners/${+match.params.ownerId}/applications/${+match.params.applicationId}/display/configurations`} >
                      {t('button.displayRoleAndPermission')}
                  </Link>
              </div>
              <div className="col s12"><br/></div>
            </div>
            {/**Stepper Workflow */}
            <ul data-method="GET" id="stepper_1" className="stepper linear">
              <li className={`step ${activeStep === 1 ? " active": ""}`}>
                <PermissionStepperItem 
                  nextStep = {activeStepper}
                  permissionNotification = {setPermission}/> {/**activeStepper = 2 */}
              </li>
              <li className={`step ${activeStep === 2 ? " active": ""}`}>
                <RoleStepperItem 
                  backStep = {activeStepper} 
                  nextStep ={activeStepper}
                  roleNotification = {setRoles}/> 
                {/**activeStepper = 1 for backStep || activeStepper = 3 for nexStep */}
              </li>
              <li className={`step ${activeStep === 3 ? " active": ""}`}>
                {/**Grid for assigning permission to a role */}
                    <AssignPermissionRoleStepperItem
                      applicationId = {match.params.applicationId}
                      applicationOwnerId = {match.params.ownerId}
                      backStep = {activeStepper} 
                      roles = {rolesDef}
                      permissions = {permissionsDef}
                    />
              </li>
            </ul>
            {/**End Stepper Workflow */}
      </div>
    </div>
  );
}
  
export default SetRoleAndPermission;