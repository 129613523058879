import SubscribedApplication from "../models/subscribe-application";
import User from "../models/users";

export default class AdminService {

    static addAdmin (user: User): Promise<Response> {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-type':'application/json'
            },
            body: JSON.stringify(user)
        };
        return fetch(`/ecauthenticateapi/admin/user`, requestOptions)
    }

    // Save Rejection application
    static updateApplication(applications: SubscribedApplication[]): Promise<Response> {
        const authToken = sessionStorage.getItem('token')+'';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': authToken,
                'Content-type':'application/json'
            },
            body: JSON.stringify(applications)
        };
        return fetch(`/ecauthenticateapi/admin/manage/subscription/save/rejectionValue`, requestOptions);
    }
}